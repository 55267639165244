<template>
    <component
        :is="props.isTopLevel ? 'div' : 'li'"
        class="nav-item"
    >
        <tooltip
            :content="props.locked && props.lockedMessage ? props.lockedMessage : ''"
        >
            <template #trigger>
                <a
                    :href="link"
                    class="z-b3"
                    :class="{
                        'locked': props.locked,
                        'active': props.active,
                        'is-top-level': props.isTopLevel,
                        'custom-slot': hasCustomSlot
                    }"
                    v-bind="props.attributes"
                    @click="handleClick"
                >
                    <slot>
                        <LockedIconSVG
                            v-if="props.locked"
                            class="nav-item-icon locked"
                        />
                        <component
                            :is="iconSvg"
                            v-if="iconSvg && !props.locked"
                            class="nav-item-icon"
                        />
                        {{ props.title }}
                    </slot>
                </a>
            </template>
        </tooltip>
    </component>
</template>

<script setup lang="ts">
import { computed, withDefaults, useSlots } from 'vue';
import LockedIconSVG from '@icons/navigation/ic_locked.svg';
import { useIconLoader } from '@ts/Navigation/useIconLoader';
import Tooltip from '@components/Navigation/NavTooltip.vue'

const props = withDefaults(defineProps<{
    id?: string,
    title?: string,
    href: string,
    icon?: string,
    locked?: boolean,
    attributes?: Record<string, string>,
    isTopLevel?: boolean,
    active?: boolean,
    lockedMessage?: string,
}>(), {
    id: '',
    title: '',
    icon: '',
    locked: false,
    attributes: () => ({}),
    isTopLevel: false,
    active: false,
    lockedMessage: '',
});

const emit = defineEmits(['clicked']);

const link = computed((): string => {
    if (props.locked) {
        return '#';
    }
    return props.href;
});

const { iconSvg } = useIconLoader(props.icon);

const handleClick = (event: Event) => {
    if (props.locked) {
        event.preventDefault();
        event.stopPropagation();
    } else {
        emit('clicked', props );
    }
};

const slots = useSlots();
const hasCustomSlot = computed(() => !!slots.default);

</script>
<style lang="scss" scoped>

.nav-item {
    a {
        background-color: var(--zumba-gray-600);
        border-bottom: 1px solid var(--zumba-gray-700);
        font-family: var(--zumba-font-secondary);
        color: var(--zumba-white);
        display: inline-flex;
        align-items: center;
        gap: 0.5rem;
        padding: 1rem 1.3rem 1rem 1.5rem;
        width: 100%;

        &.custom-slot {
            justify-content: space-between;
        }

        .nav-item-icon {
            width: 1.5rem;
            height: 1.5rem;

            &:deep(path) {
                fill: var(--zumba-white);
            }
        }

        &:hover {
            color: var(--zumba-white);
            .nav-item-icon {
                &:deep(path) {
                    fill: var(--zumba-white);
                }
            }
        }

        &.locked {
            background-color: var(--zumba-gray-400);
            color: var(--zumba-gray-200);
            cursor: not-allowed;

            .nav-item-icon {
                &:deep(path) {
                    fill: var(--zumba-gray-200);
                }
            }
        }

        &.is-top-level {
            background-color: var(--zumba-gray-800);
            border-bottom: 1px solid var(--zumba-gray-600);
        }

        &.active {
            background-color: var(--zumba-plum);
            font-weight: 700;
        }

    }
}

.theme-strong {
    .nav-item {
        a {
            font-family: var(--strong-font-default);

            &:hover {
                color: var(--strong-primary-coral);
                .nav-item-icon {
                    &:deep(path) {
                        fill: var(--strong-primary-coral);
                    }
                }
            }

            &.is-top-level {
                background-color: var(--strong-primary-gray);
            }

            &.active {
                background-color: var(--strong-primary-coral);
                font-weight: 700;
            }
        }
    }
}

.label-with-right-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

</style>
