<template>
    <hr
        :class="classes"
        :data-content="inlineMessage"
    >
</template>

<script setup lang="ts">
import { computed } from 'vue';

defineProps({
    inlineMessage: {
        type: String,
        default: '',
    },
})

const classes = computed((): Record<string, boolean> => {
    return {
        hr: true,
    }
})

</script>


<style scoped lang="scss">
.hr {
    position: relative;
    outline: 0;
    border: 0;
    text-align: center;
    height: unset;

    &::before {
        content: '';
        background-color: var(--zumba-gray-300);
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        height: 1px;
    }

    &::after {
        content: attr(data-content);
        position: relative;
        display: inline-block;
        padding: 0 .5rem;
        background-color: var(--zumba-white);
    }
}
</style>
