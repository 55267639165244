<template>
    <div
        v-if="props.animation === 'slide'"
        ref="flexViewportRef"
        class="flex-viewport"
        style="overflow: hidden; position: relative;"
    >
        <slot />
    </div>
    <slot v-else />

    <ol
        v-if="shouldAddDotButtons"
        ref="controlButtons"
        :class="{
            'flex-control-nav': true,
            'flex-control-paging': true,
            'flex-control-compacted': props.buttonsCompacted,
        }"
    >
        <li
            v-for="(slidesButtonNumber) in dotButtonsIndexesRef"
            :key="slidesButtonNumber"
        >
            <a @click="handleClick(slidesButtonNumber)">{{ slidesButtonNumber + 1 }}</a>
        </li>
    </ol>
    <ul
        v-if="shoudlAddDirectionButtons"
        class="flex-direction-nav"
        :class="{'one-way-direction': props.isOneDirectionNav}"
    >
        <li
            v-show="!props.isOneDirectionNav"
            class="flex-nav-prev"
        >
            <a
                class="flex-prev"
                @click="handlePreviousClick()"
            >
                Previous
            </a>
        </li>
        <li class="flex-nav-next">
            <a
                v-if="props.oneDirectionButtonCustomClass === ''"
                class="flex-next"
                @click="handleNextClick()"
            >
                Next
            </a>
            <a
                v-else
                class="flex-next"
                @click="handleNextClick()"
            >
                <div :class="{[props.oneDirectionButtonCustomClass]: true}" />
            </a>
        </li>
    </ul>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
let activeSlide: HTMLLIElement;
let activeButton: HTMLAnchorElement;
let lastIndex: number;
let currentSlide: number = 0;
let timer;
let initialMousePosition;
let finalMousePosition;
let slides: NodeListOf<Element> | [] = []; // eslint-disable-line no-undef
const controlButtons = ref<HTMLOListElement>();
const flexViewportRef = ref<HTMLElement>();
const width = ref('0px');
const margin = ref('0px');
const slideSpeed = ref('600ms');
const props = defineProps({
    slideSelector: { type: String, default: ''},
    sliderSelector: { type: String, default: '' },
    includeDotButtons: { type: Boolean, default: true },
    includeDirectionButtons: { type: Boolean, default: false },
    buttonsCompacted: { type: Boolean, default: false },
    interval: { type: Number, default: 7000 },
    animation: { type: String, default: 'fade' },
    slideAnimationSpeed: { type: Number, default: 600 },
    amountOfSlides: { type: Number, default: 1 },
    maxItems: { type: Number, default: 1 },
    itemWidth: { type: Number, default: null },
    move: { type: Number, default: 1 },
    startAtIndex: { type: Number, default: 0 },
    isOneDirectionNav: { type: Boolean, default: false },
    oneDirectionButtonCustomClass: { type: String, default: '' },
    manualControlListSelector: { type: String, default: '' },
    manualArrowsSelector: { type: String, default: '' },
    forceAlwaysItemWidth: { type: Boolean, default: false },
    sliderWidthPercentage: { type: Number, default: 1000 },
    membershipType: { type: String, default: 'zumba' },
    autoscroll: { type: Boolean, default: true },
});
const sliderWidth = computed(() => {
    if (props.sliderWidthPercentage !== 1000) {
        return `${(props.itemWidth/100)*props.sliderWidthPercentage}px`
    }
    return `${props.sliderWidthPercentage}%`
})
const sliderWidthRef = ref(sliderWidth);
const shouldAddDotButtons = computed(() => props.amountOfSlides > 1 && props.includeDotButtons)
const shoudlAddDirectionButtons = computed(() => {
    return props.amountOfSlides > 1
        && props.includeDirectionButtons
        && props.amountOfSlides > props.maxItems;
})
const isOnDesktop = () => window.innerWidth > 768

const doGetDotButtonsIndexes = () => {
    const start = isOnDesktop() && props.maxItems ? props.maxItems : 1;
    const step = isOnDesktop() ? props.move : 1;
    const buttonsIndexes = Array.from(
        { length: Math.ceil(((props.amountOfSlides - start) / step)) + 1 },
        (v, i) => i
    );
    return isOnDesktop() && props.amountOfSlides <= props.maxItems ? [] : buttonsIndexes;
}

const dotButtonsIndexesRef = ref(doGetDotButtonsIndexes());

onMounted(() => {
    setWidth()
    setUpSlidesAnimationClass();
    setUpSlideAnimationIfNeeded();
    setUpSwipeEvents();
    setUpManualCustomControlsIfNeeded();
    if (lastIndex !== 0 && props.autoscroll) {
        timer = setInterval(() => goToNextSlide(), props.interval);
    }
    goToSlide(props.startAtIndex);
})

const getMinWidth = () => {
    if (props.itemWidth) {
        return props.forceAlwaysItemWidth
            ? props.itemWidth
            : Math.min(props.itemWidth, flexViewportRef.value?.offsetWidth ?? 0);
    }
    return flexViewportRef.value?.offsetWidth;
}

const setWidth = () => width.value = `${getMinWidth()}px`

const setMargin = () => {
    const step = isOnDesktop() ? props.move : 1;
    margin.value = `-${(getMinWidth()) * (currentSlide * step)}px`
}

const setLastIndex = () => lastIndex = Math.max(0, dotButtonsIndexesRef.value.length - 1);

const setUpManualCustomControlsIfNeeded = () => {
    if (props.manualControlListSelector !== '') {
        const controlItems = Array.from(document.querySelector(props.manualControlListSelector)?.children ?? []);
        controlItems.forEach((controlItem, index) => {
            controlItem.addEventListener('click', function(){ handleClick(index) }, false);
        });
    }
    if (props.manualArrowsSelector !== '') {
        const [left, right] = Array.from(document.querySelectorAll(props.manualArrowsSelector));
        if (left) left.addEventListener('click', handlePreviousClick, false);
        if (right) right.addEventListener('click', handleNextClick, false);
    }
}

const setUpSlidesAnimationClass = () => {
    slides = document.querySelectorAll(props.slideSelector);
    slides.forEach(slide => slide.classList.add(props.animation));
    setLastIndex();
}

const setUpSwipeEvents = () => {
    doSetUpSwipeEvents(props.sliderSelector);
    if (shoudlAddDirectionButtons.value) {
        doSetUpSwipeEvents('.flex-direction-nav');
    }
}

const doSetUpSwipeEvents = (querySelector: string) => {
    const slider = document.querySelector(querySelector);
    slider?.addEventListener('touchstart', handleTouchStart, {passive: true})
    slider?.addEventListener('touchmove', handleTouchMove, {passive: false});
    slider?.addEventListener('touchend', handleTouchEnd, {passive: true});
}

const handleTouchStart = (e) => initialMousePosition = e.targetTouches[0];

const handleTouchMove = (e) => {
    finalMousePosition = e.targetTouches[0];
    const moveX = Math.abs(finalMousePosition.pageX - initialMousePosition.pageX);
    const moveY = Math.abs(finalMousePosition.pageY - initialMousePosition.pageY);
    // Prevent horizontal scrolling when swiping sideways
    if (moveX > moveY) {
        e.preventDefault();
    }
}

const handleTouchEnd = () => {
    if (!finalMousePosition) {
        tearDownPositions();
        return;
    }
    const isLeftMove = finalMousePosition.pageX > initialMousePosition.pageX;
    // Avoid moving the slider on minimal swipes
    const isMinimalSwipe = Math.abs(finalMousePosition.pageX - initialMousePosition.pageX) < initialMousePosition.pageX * 0.1;
    if (isMinimalSwipe) {
        tearDownPositions();
        return;
    }
    if (isLeftMove) {
        handlePreviousClick();
    } else {
        handleNextClick();
    }
    tearDownPositions();
}

const tearDownPositions = () => {
    finalMousePosition = null;
    initialMousePosition = null;
}

const setUpSlideAnimationIfNeeded = () => {
    if (props.animation === 'slide') {
        setWidth();
        slideSpeed.value = `${props.slideAnimationSpeed}ms`
        window.addEventListener("resize", handleResize);
        document.querySelector(props.sliderSelector)?.classList.add('slider-active');
    }
}

const handleResize = () => {
    setWidth();
    setMargin();
    setDotButtonsIndexes()
    setLastIndex();
    goToSlide(currentSlide);
}

const setDotButtonsIndexes = () => dotButtonsIndexesRef.value = doGetDotButtonsIndexes();

const handleNextClick = () => {
    resetTimer();
    goToNextSlide();
}

const handlePreviousClick = () => {
    resetTimer();
    goToPreviousSlide();
}

const resetTimer = () => {
    clearInterval(timer);
    timer = setInterval(() => goToNextSlide(), props.interval);
}

const handleClick = (index: number) => {
    resetTimer();
    goToSlide(index);
}

const goToNextSlide = () => {
    if (currentSlide === lastIndex) {
        goToSlide(0);
    } else {
        goToSlide(currentSlide+1);
    }
}

const goToPreviousSlide = () => {
    if (currentSlide === 0) {
        goToSlide(lastIndex);
    } else {
        goToSlide(currentSlide-1);
    }
}

const goToSlide = (index: number) => {
    currentSlide = index;
    const slide = slides[index];
    const button = props.manualControlListSelector !== ''
        ? document.querySelector(props.manualControlListSelector)?.children[index]
        : controlButtons.value?.children[index]?.firstElementChild as HTMLAnchorElement
    toggleSlideClasses(slide);
    toggleButtonClasses(button);
}

const toggleButtonClasses = (button) => {
    activeButton?.classList.remove('flex-active-' + props.membershipType);
    button?.classList.add('flex-active-' + props.membershipType);
    activeButton = button;
}

const toggleSlideClasses = (slide) => {
    if (props.animation === 'fade') {
        activeSlide?.classList.remove(`${props.animation}-active`);
        slide?.classList.add(`${props.animation}-active`);
        activeSlide = slide;
    } else {
        setMargin();
    }
}

</script>

<style lang="scss" scoped>
:slotted(.fade) {
    width: 100%;
    float: left;
    margin-right: -100%;
    position: relative;
    display: block;
    transition: opacity 0.6s ease 0s;
    opacity: 0;
    z-index: 1;
}
:slotted(.fade-active) {
    opacity: 1;
    z-index: 2;
}
:slotted(.slide) {
    width: v-bind(width);
    margin-right: 0px;
    float: left;
    display: block;
}
:slotted(.slider-active) {
    width: v-bind(sliderWidthRef);
    margin-left: v-bind(margin);
    transition: margin v-bind(slideSpeed);
}
.flex-control-nav {
	width: 100%;
	margin-left: 0;
	text-align: center;

    &.flex-control-compacted {
        line-height: 0;
        margin-bottom: 0;
    }

	li {
		display: inline-block;
		margin: 0 .375em;
		zoom: 1;

		a {
			display: block;
			width: .688em;
			height: .688em;
			background: var(--zumba-gray-200);
			cursor: pointer;
			text-indent: -9999px;
			border-radius: 50%;

			&.flex-active {
				background-color: #be2d93;
				cursor: default;
			}

			&.flex-active-zumba {
				background-color: #be2d93;
				cursor: default;
			}

            &.flex-active-zumba_app {
                background-color: var(--zumba-gray-800);
                cursor: default;
            }

			&.flex-active-strong {
				background-color: #FF0061;
				cursor: default;
			}

		}
	}
}
</style>
