<template>
    <template v-if="props.isAuthenticated">
        <a
            class="gh-profile-nav-name ht-user-session"
            href="#"
            data-dropdown="nav-profile"
            @click.prevent
        >
            {{ props.profileName }}
        </a>
        <ul
            id="nav-profile"
            class="dropdown-mtv dd-right profile-dropdown"
            :class="{
                'pd-zin': props.canAccessMemberMenu,
                'pd-gym': (!props.canAccessMemberMenu && props.canManageGymProfile)
            }"
        >
            <UserMenu
                :t="props.t"
                :current="props.current"
                :can-access-member-menu="props.canAccessMemberMenu"
                :can-access-six-weeks-program-profile="props.canAccessSixWeeksProgramProfile"
                :can-consumer-view-dashboard="props.canConsumerViewDashboard"
                :can-gym-owner-view-dashboard="props.canGymOwnerViewDashboard"
                :can-manage-gym-profile="props.canManageGymProfile"
                :can-manage-linked-gym-instructors="props.canManageLinkedGymInstructors"
                :current-user-locale="props.currentUserLocale"
                :ga-label="props.gaLabel"
                :gym-active-link="props.gymActiveLink"
                :is-authenticated="props.isAuthenticated"
                :is-trained-non-member="props.isTrainedNonMember"
                :item-class="props.itemClass"
                :link-class="props.linkClass"
                :should-show-join-link="props.shouldShowJoinLink"
                :user-has-delinquent-membership="props.userHasDelinquentMembership"
                :user-has-benefit-member-portal="props.userHasBenefitMemberPortal"
                :zin-responsive-link="props.zinResponsiveLink"
                :can-view-student-dashboard="props.canViewStudentDashboard"
                :is-pre-payment-access-enabled="props.isPrePaymentAccessEnabled"
            />
        </ul>
    </template>
    <template v-else>
        <div
            v-if="props.isAclHeaderEnabled"
            class="ht-user-session__actions-container"
        >
            <a
                class="ht-user-session"
                :href="props.loginUrl"
            >
                {{ props.t.logInText }}
            </a>
            <a
                class="ht-user-session"
                :href="props.signUpUrl"
            >
                {{ props.t.signUpText }}
            </a>
        </div>
        <a
            v-else
            class="ht-user-session"
            :href="props.loginUrl"
            data-ga4-event-name="sign_in"
            data-ga4-action="Sign In - Button Click"
        >
            {{ props.t.signInText }}
        </a>
    </template>
</template>

<script setup lang="ts">
import UserMenu from './UserMenu.vue';

const props = defineProps({
    t: {
        type: Object,
        default: () =>({
            myZinHomeText: 'My Zin Home',
            myDashboardText: 'My Dashboard',
            joinZinText: 'Join ZIN™',
            updatePaymentText: 'Update Payment',
            instructorTrainingsText: 'Instructor Trainings',
            sixWeekProgramText: '6 Week Program',
            myTrainingsText: 'My Trainings',
            manageMyAccountText: 'Manage My Account',
            myAccountText: 'My Account',
            signInText: 'Sign In',
            signOutText: 'Sign Out',
            myAnnouncementsText: 'My Announcements',
            marketingMaterialsText: 'Marketing Materials',
            findAnInstructorGymText: 'Find An Instructor',
            manageInstructorsText: 'Manage Instructors',
            hostATrainingText: 'Host a Training',
            zumbaForGymsText: 'Zumba For Gyms',
            gymAuditionKitText: 'Gym Audition Kit',
            manageLocationsText: 'Manage Locations',
            accountSummaryText: 'Account Summary',
            gymsProfileText: 'Gyms Profile',
            findAnInstructorText: 'Find an Instructor',
            logInText: 'Log In',
            signUpText: 'Sign Up',
        })
    },
    current: {type: String, default: ''},
    canAccessMemberMenu: {type: String, default: ''},
    canAccessSixWeeksProgramProfile: {type: String, default: ''},
    canConsumerViewDashboard: {type: String, default: ''},
    canGymOwnerViewDashboard: {type: String, default: ''},
    canManageGymProfile: {type: String, default: ''},
    canManageLinkedGymInstructors: {type: String, default: ''},
    currentUserLocale: {type: String, default: ''},
    gaLabel: {type: String, default: ''},
    gymActiveLink: {type: String, default: ''},
    isAuthenticated: {type: String, default: ''},
    isTrainedNonMember: {type: String, default: ''},
    itemClass: {type: String, default: ''},
    linkClass: {type: String, default: ''},
    loginUrl: {type: String, default: ''},
    signUpUrl: {type: String, default: ''},
    profileName: {type: String, default: ''},
    shouldShowJoinLink: {type: String, default: ''},
    userHasDelinquentMembership: {type: String, default: ''},
    userHasBenefitMemberPortal: {type: String, default: ''},
    zinResponsiveLink: {type: String, default: ''},
    isAclHeaderEnabled: {type: Boolean, default: false},
    canViewStudentDashboard: {
        type: Boolean,
        default: false
    },
    isPrePaymentAccessEnabled: {type: String, default: ''},
})

</script>

<style scoped>
.ht-user-session__actions-container a.ht-user-session {
    padding-right: 2em;
}
.ht-user-session:hover {
    color: var(--zumba-white);
}
.ht-user-session.gh-profile-nav-name:after {
    transform: rotate(90deg);
    top: 0.3em;
}
.ht-user-session .chevron {
    margin-left: 0.25em;
    position: inherit;
    right: inherit;
    top: inherit;
    display: inline-block;
}
.ht-user-session .chevron.down::before {
    border-width: 2px 2px 0 0;
    height: 8px;
    width: 8px;
    top: 0;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
    content: '';
    border-style: solid;
    display: inline-block;
    left: 0.15em;
    position: relative;
    vertical-align: top;
}
.ht-user-session .chevron.white::before {
    border-color: var(--zumba-white);
}
.ht-user-session:hover + .dropdown-mtv {
    display: block;
}
.header-toggle .ht-user-session {
    display: none;
    line-height: 0.9em;
}
.header-toggle .ht-user-session:hover {
    color: var(--zumba-white);
}
.header-toggle .ht-user-session.gh-profile-nav-name:after {
    top: 0.3em;
}
.header-toggle .ht-user-session .chevron {
    margin-left: 0.25em;
    position: inherit;
    right: inherit;
    top: inherit;
    display: inline-block;
}
.header-toggle .ht-user-session .chevron.down::before {
    border-width: 2px 2px 0 0;
    height: 8px;
    width: 8px;
    top: 0;
    content: '';
    border-style: solid;
    display: inline-block;
    left: 0.15em;
    position: relative;
    vertical-align: top;
}
.header-toggle .ht-user-session .chevron.white::before {
    border-color: var(--zumba-white);
}
.header-toggle .ht-user-session:hover + .dropdown-mtv {
    display: block;
}
.header-toggle .dropdown-mtv {
    background: var(--zumba-gray-100);
}
.header-toggle .dropdown-mtv:before {
    border-color: transparent transparent var(--zumba-gray-100) transparent;
}
.header-toggle .dropdown-mtv a {
    font-size: 14px;
}
.dropdown-mtv li a {
    font-weight: normal;
    font-family: "Zandes-Medium", Helvetica, Arial, sans-serif;
}
.dropdown-mtv {
    padding: 0.5em 0;
    border: 0;
    background: #d43361;
    z-index: 9999;
    margin-top: 1em;
    text-align: left;
    width: 14em;
    left: 0;
    top: 1.75em !important;
    display: none;
    position: absolute;
    list-style: none;
    margin-left: 0;
    max-height: none;
    height: auto;
    margin-top: 2px;
}
.dropdown-mtv:before {
    content: '';
    top: -1em;
    border: solid 8px;
    border-color: transparent transparent #d43361 transparent;
    position: absolute;
    left: 1em;
}
.dropdown-mtv:after {
    content: '';
    position: absolute;
    left: 0;
    top: -1em;
    padding: 0.6em 0;
    display: block;
    width: 100%;
}
.dropdown-mtv:hover {
    display: block;
}
.dropdown-mtv.dd-right {
    left: initial !important;
    right: 0;
}
.dropdown-mtv.dd-right:before {
    left: initial;
    right: 1em;
}
.dropdown-mtv li a {
    padding: 1em 1.25em;
    font-size: 0.8125rem;
    color: #1d1d24;
    text-transform: uppercase;
    line-height: 1em;
    display: block;
}
.dropdown-mtv li a:hover {
    background: var(--zumba-gray-400);
}
.global-header .dropdown-mtv {
    background: #d43361;
    -webkit-border-radius: 0.2em;
    -moz-border-radius: 0.2em;
    -ms-border-radius: 0.2em;
    -o-border-radius: 0.2em;
    border-radius: 0.2em;
}
.global-header .dropdown-mtv:before {
    border-color: transparent transparent #d43361 transparent;
}
.global-header .dropdown-mtv li a {
    font-family: var(--zumba-font-default);
    font-size: 0.8125rem;
    color: var(--zumba-white);
    cursor: pointer;
}
.global-header .dropdown-mtv li a:hover {
    background: var(--zumba-gray-800);
}
@media only screen and (min-width:64em) {
    .header-toggle .ht-secondary-nav {
        margin: 0.72em 1em 0 0;
        float: right;
    }
    .header-toggle .ht-user-session {
        display: inherit !important;
        position: relative;
        padding-right: 0.9em;
        color: var(--zumba-white);
        font-size: 14px;
        cursor: pointer;
        font-style: normal;
        font-weight: normal;
        font-family: "Zandes-Medium", Helvetica, Arial, sans-serif;
    }
    .header-toggle .ht-user-session:after {
        content: none;
    }
    .header-toggle {
        display: block;
        margin: 0;
        padding: 0;
    }
    .ht-secondary-nav {
        margin: .72em 1em 0 0;
        float: right;
    }
    .ht-user-session:after {
        content: none;
    }

    .ht-user-session__actions-container {
        display: flex;
    }
}
</style>
