<template>
    <p>
        <span ref="contentRef">
            {{ content }}
        </span>
        <span v-if="isContentLongEnough">
            <span v-if="!showMore">
                ...
                <br>
                <span
                    ref="moreLinkRef"
                    class="show-more"
                    @click="toggleShowMore()"
                >{{ props.t.moreLinkText }}</span>
            </span>
            <a
                v-if="showMore"
                ref="lessLinkRef"
                class="show-less"
                @click="toggleShowMore()"
            >{{ props.t.lessLinkText }}</a>
        </span>
    </p>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';

const props = defineProps({
    t: {
        type: Object,
        default: () => ({
            moreLinkText: 'More',
            lessLinkText: 'Less'
        })
    },
    paragraphContent: {type: String, default: ''},
    limit: {type: Number, default: 100}
});
const isContentLongEnough = computed(() => props.paragraphContent.length > props.limit);
const content = computed(() => showMore.value ? props.paragraphContent : props.paragraphContent.substring(0, props.limit));
const contentRef = ref<HTMLElement>();
const moreLinkRef = ref<HTMLElement>();
const lessLinkRef = ref<HTMLElement>();
const showMore = ref(!isContentLongEnough.value);
const toggleShowMore = () => showMore.value = !showMore.value;

</script>

<style scoped>
.show-more, .show-less {
    text-decoration: underline;
    color: #484c54;
    cursor: pointer;
}
</style>
