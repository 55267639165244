<template>
    <ZButton
        href="#"
        class="back-to-top"
        variety="robin"
        wide="never"
        :class="{
            'btt-active': state.isActive,
            'btt-stuck': state.isStuck
        }"
        @click="handleClick"
    >
        {{ __z('globals/TOP', 'Top') }}
    </ZButton>
</template>

<script setup lang="ts">
import ZButton from "@components/Core/ZButton.vue"
import { reactive, onMounted } from 'vue'
import { __z } from '@ts/Util/i18n'

const handleClick = () => {
    document.getElementById('layout-page-content')?.scrollIntoView()
}

const state = reactive({
    isActive: false,
    isStuck: false
})

onMounted(() => {
    addHeaderObserver();
    addFooterObserver();
})

const addHeaderObserver = () => {
    const observerOptions = { attributes: true }
    const toggleIsActive = (mutationList) => {
        state.isActive = mutationList[0].target.classList.contains('sticky');
    }
    document.addEventListener("DOMContentLoaded", function() {
        const headerElement = document.getElementsByTagName('header')[0];
        const observer = new MutationObserver(toggleIsActive);
        observer.observe(headerElement, observerOptions);
    }, {once: true});
}

const addFooterObserver = () => {
    const observerOptions = { threshold: 0 };
    document.addEventListener("DOMContentLoaded", () => {
        const footerElement = document.getElementsByTagName('footer')[0];
        const toggleIsStuck = (entries) => { state.isStuck = entries[0].isIntersecting; }
        const observer = new IntersectionObserver(toggleIsStuck, observerOptions);
        observer.observe(footerElement);
    }, {once: true});
}
</script>

<style scoped>
	.back-to-top {
		position: fixed;
		right: 20px;
		bottom: 6.2em;
		z-index: 10000;
		opacity: 0;
	}

	@media only screen and (min-width: 64em) {
		.back-to-top {
			display: none;
		}
	}

	@media print {
		.back-to-top {
			display: none;
		}
	}

	.back-to-top.btt-active {
		opacity: 1;
	}

	.back-to-top.btt-stuck {
		position: absolute;
		bottom: 6.2em;
	}
</style>
