<template>
    <section
        v-if="showPopup"
        class="privacy-policy"
    >
        <a
            class="close"		
            @click.prevent="handleOnClose"
        />
        <p :class="{ 'f-inter': theme === ThemeType.Zumba }">
            <slot />
        </p>
    </section>
</template>

<script setup lang="ts">
import {ref, Ref} from 'vue'
import { addCookie } from '@ts/Util/cookies'
import {theme, ThemeType} from "@ts/Util/theme";

const showPopup: Ref<boolean> = ref(true)

const handleOnClose = () => {
    const now: Date = new Date();
    now.setFullYear(now.getFullYear() + 1)

    addCookie('eu_privacy', '1', now)
    showPopup.value = false
}
</script>

<style lang="scss" scoped>
.privacy-policy {
	padding: 0;
	background: #ccc;
	margin: 0;
	max-height: 20em;
	overflow: auto;

	@media only screen and (max-width: 48em) {
		position: fixed;
		bottom: 0;
		z-index: 1000;
	}

	& p {
		padding: .5em 5em;
		color: #333;
		margin: 0;
		line-height: 1.6em;
		text-rendering: optimizeLegibility;
		-webkit-font-smoothing: antialiased;
		font-size: 0.875rem;

		:deep(a) {
			font-family: inherit;
		}

		@media only screen and (max-width: 48em) {
			padding: .5em 1em;
			font-size: .75rem
		}
	}

	& .close {
		float: right;
		width: 10px;
		height: 11px;
		margin: .5em;
		background: url("~@icons/close-privacy.png?external") 0 0 no-repeat;

		&:hover {
			cursor: pointer;
		}
	}

	&.pp-slide-up {
		max-height: 0;
		overflow: hidden;
	}
}
</style>
