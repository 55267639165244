<template>
    <section class="header-toggle">
        <div class="brands">
            <a
                :href="props.zumbaLink"
                class="ht-brand ht-zumba"
                data-ga-category="Global Navigation"
                :data-ga-action="props.zumbaDataName"
                :data-ga-label="props.uri"
                data-ga4-event-name="global_navigation"
                :data-ga4-action="props.zumbaDataName"
                :data-ga4-details="props.uri"
                :class="{'active': props.current == 'zumba'}"
            >
                {{ props.zumbaDataName }}
            </a>
            <a
                :href="props.strongLink"
                class="ht-brand ht-strong-nation"
                :class="{
                    'ht-strong-nation': props.isCmsStrongNationEnabled,
                    'ht-strong': !props.isCmsStrongNationEnabled,
                    'active': props.current == 'strong'
                }"
                data-ga-category="Global Navigation"
                :data-ga-action="props.strongDataName"
                :data-ga-label="props.uri"
                data-ga4-event-name="global_navigation"
                :data-ga4-action="props.strongDataName"
                :data-ga4-details="props.uri"
            >
                {{ props.strongDataName }}
            </a>
            <a
                v-if="props.isZumbiniGlobalHeaderEnabled"
                href="https://www.zumbini.com"
                class="ht-zumbini ht-brand"
                data-ga-category="Global Navigation"
                data-ga-action="Zumbini"
                :data-ga-label="props.uri"
                data-ga4-event-name="global_navigation"
                data-ga4-action="Zumbini"
                :data-ga4-details="props.uri"
                target="_blank"
                rel="noopener"
            >
                {{ props.t.zumbiniText }}
            </a>
        </div>
        <div class="ht-settings">
            <ul class="ht-secondary-nav">
                <li class="ht-secondary-nav-item">
                    <Profile
                        :t="props.t"
                        :current="props.current"
                        :can-access-member-menu="props.canAccessMemberMenu"
                        :can-access-six-weeks-program-profile="props.canAccessSixWeeksProgramProfile"
                        :can-consumer-view-dashboard="props.canConsumerViewDashboard"
                        :can-gym-owner-view-dashboard="props.canGymOwnerViewDashboard"
                        :can-manage-gym-profile="props.canManageGymProfile"
                        :can-manage-linked-gym-instructors="props.canManageLinkedGymInstructors"
                        :current-user-locale="props.currentUserLocale"
                        :ga-label="props.gaLabel"
                        :gym-active-link="props.gymActiveLink"
                        :is-authenticated="props.isAuthenticated"
                        :is-trained-non-member="props.isTrainedNonMember"
                        :item-class="props.itemClass"
                        :link-class="props.linkClass"
                        :login-url="props.loginUrl"
                        :sign-up-url="props.signUpUrl"
                        :profile-name="props.profileName"
                        :should-show-join-link="props.shouldShowJoinLink"
                        :user-has-delinquent-membership="props.userHasDelinquentMembership"
                        :user-has-benefit-member-portal="props.userHasBenefitMemberPortal"
                        :zin-responsive-link="props.zinResponsiveLink"
                        :is-acl-header-enabled="props.isAclHeaderEnabled"
                        :can-view-student-dashboard="props.canViewStudentDashboard"
                        :is-pre-payment-access-enabled="props.isPrePaymentAccessEnabled"
                    />
                </li>
                <li class="ht-secondary-nav-item">
                    <a
                        href="#"
                        class="ht-user-session lang"
                        @click.prevent
                    >
                        {{ props.t.userLanguageText }}
                        <span class="chevron down white" />
                    </a>
                    <ul class="dropdown-mtv dd-right">
                        <li>
                            <a
                                :href="'/en-US' + props.uri"
                                data-ga-category="Global Language Switch"
                                data-ga-action="English"
                                :data-ga-label="props.gaLabel"
                                data-ga4-event-name="global_navigation"
                                data-ga4-action="English"
                                :data-ga4-details="props.gaLabel"
                            >
                                {{ props.t.englishText }}
                            </a>
                        </li>
                        <li>
                            <a
                                :href="'/fr-FR' + props.uri"
                                data-ga-category="Global Language Switch"
                                data-ga-action="French"
                                :data-ga-label="props.gaLabel"
                                data-ga4-event-name="global_navigation"
                                data-ga4-action="French"
                                :data-ga4-details="props.gaLabel"
                            >
                                {{ props.t.frenchText }}
                            </a>
                        </li>
                        <li>
                            <a
                                :href="'/it-IT' + props.uri"
                                data-ga-category="Global Language Switch"
                                data-ga-action="Italian"
                                :data-ga-label="props.gaLabel"
                                data-ga4-event-name="global_navigation"
                                data-ga4-action="Italian"
                                :data-ga4-details="props.gaLabel"
                            >
                                {{ props.t.italianText }}
                            </a>
                        </li>
                        <li>
                            <a
                                :href="'/es-ES' + props.uri"
                                data-ga-category="Global Language Switch"
                                data-ga-action="Spanish"
                                :data-ga-label="props.gaLabel"
                                data-ga4-event-name="global_navigation"
                                data-ga4-action="Spanish"
                                :data-ga4-details="props.gaLabel"
                            >
                                {{ props.t.spanishText }}
                            </a>
                        </li>
                        <li>
                            <a
                                :href="'/pt-BR' + props.uri"
                                data-ga-category="Global Language Switch"
                                data-ga-action="Portuguese"
                                :data-ga-label="props.gaLabel"
                                data-ga4-event-name="global_navigation"
                                data-ga4-action="Portuguese"
                                :data-ga4-details="props.gaLabel"
                            >
                                {{ props.t.portugueseText }}
                            </a>
                        </li>
                        <li>
                            <a
                                :href="'/de-DE' + props.uri"
                                data-ga-category="Global Language Switch"
                                data-ga-action="Deutsch"
                                :data-ga-label="props.gaLabel"
                                data-ga4-event-name="global_navigation"
                                data-ga4-action="Deutsch"
                                :data-ga4-details="props.gaLabel"
                            >
                                {{ props.t.deutschText }}
                            </a>
                        </li>
                        <li>
                            <a
                                :href="'/ko-KR' + props.uri"
                                data-ga-category="Global Language Switch"
                                data-ga-action="Korean"
                                :data-ga-label="props.gaLabel"
                                data-ga4-event-name="global_navigation"
                                data-ga4-action="Korean"
                                :data-ga4-details="props.gaLabel"
                            >
                                {{ props.t.koreanText }}
                            </a>
                        </li>
                        <li>
                            <a
                                :href="'/id-ID' + props.uri"
                                data-ga-category="Global Language Switch"
                                data-ga-action="Indonesian"
                                :data-ga-label="props.gaLabel"
                                data-ga4-event-name="global_navigation"
                                data-ga4-action="Indonesian"
                                :data-ga4-details="props.gaLabel"
                            >
                                {{ props.t.indonesianText }}
                            </a>
                        </li>
                        <li v-if="props.isJapaneseEnabled">
                            <a
                                :href="'/ja-JP' + props.uri"
                                data-ga-category="Global Language Switch"
                                data-ga-action="Japanese"
                                :data-ga-label="props.gaLabel"
                                data-ga4-event-name="global_navigation"
                                data-ga4-action="Japanese"
                                :data-ga4-details="props.gaLabel"
                            >
                                日本語
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </section>
</template>

<script setup lang="ts">
import Profile from './Profile.vue';

const props = defineProps({
    t: {
        type: Object,
        default: () =>({
            englishText: 'English',
            italianText: 'Italian',
            frenchText: 'French',
            spanishText: 'Spanish',
            portugueseText: 'Portuguese',
            deutschText: 'Deutsch',
            koreanText: 'Korean',
            indonesianText: 'Indonesian',
            signInText: 'Sign In',
            zinHomeText: 'ZIN™ Home',
            myDashboardText: 'My Dashboard',
            joinZinText: 'Join ZIN™',
            updatePaymentText: 'Update Payment',
            instructorTrainingsText: 'Instructor Trainings',
            sixWeekProgramText: '6 Week Program',
            myTrainingsText: 'My Trainings',
            manageMyAccountText: 'Manage My Account',
            myAccountText: 'My Account',
            signOutText: 'Sign Out',
            myAnnouncementsText: 'My Announcements',
            marketingMaterialsText: 'Marketing Materials',
            findAnInstructorText: 'Find An Instructor',
            findAnInstructorGymText: 'Find An Instructor',
            manageInstructorsText: 'Manage Instructors',
            hostATrainingText: 'Host a Training',
            zumbaForGymsText: 'Zumba For Gyms',
            gymAuditionKitText: 'Gym Audition Kit',
            manageLocationsText: 'Manage Locations',
            accountSummaryText: 'Account Summary',
            gymsProfileText: 'Gyms Profile',
            zumbiniText: 'Zumbini',
            userLanguageText: 'English',
            logInText: 'Log In',
            signUpText: 'Sign Up',
        })
    },
    canAccessMemberMenu: {type: String, default: ''},
    canAccessSixWeeksProgramProfile: {type: String, default: ''},
    canConsumerViewDashboard: {type: String, default: ''},
    canGymOwnerViewDashboard: {type: String, default: ''},
    canManageGymProfile: {type: String, default: ''},
    canManageLinkedGymInstructors: {type: String, default: ''},
    current: {type: String, default: ''},
    currentUserLocale: {type: String, default: ''},
    gaLabel: {type: String, default: ''},
    gymActiveLink: {type: String, default: ''},
    isAuthenticated: {type: String, default: ''},
    isCmsStrongNationEnabled: {type: String, default: ''},
    isIndonesianLanguageEnabled: {type: Boolean, default: false},
    isTrainedNonMember: {type: String, default: ''},
    isZumbiniGlobalHeaderEnabled: {type: Boolean, default: false},
    itemClass: {type: String, default: ''},
    linkClass: {type: String, default: ''},
    loginUrl: {type: String, default: ''},
    signUpUrl: {type: String, default: ''},
    profileName: {type: String, default: ''},
    shouldShowJoinLink: {type: String, default: ''},
    userHasDelinquentMembership: {type: String, default: ''},
    strongDataName: {type: String, default: ''},
    strongLink: {type: String, default: ''},
    uri: {type: String, default: ''},
    userHasBenefitMemberPortal: {type: String, default: ''},
    zinResponsiveLink: {type: String, default: ''},
    zumbaDataName: {type: String, default: ''},
    zumbaLink: {type: String, default: ''},
    isAclHeaderEnabled: {type: Boolean, default: false},
    isJapaneseEnabled: {type: Boolean, default: false},
    canViewStudentDashboard: {
        type: Boolean,
        default: false
    },
    isPrePaymentAccessEnabled: {type: String, default: ''},
})
</script>

<style scoped>
a {
    text-decoration: none;
}
.ht-secondary-nav {
    list-style: none;
    display: block;
    margin-top: .75em;
}
.ht-secondary-nav > li {
    float: left;
}
.ht-secondary-nav-item {
    margin-left: 1.5em;
    position: relative;
}
.ht-secondary-nav-item a:hover {
    text-decoration: none;
}
.ht-user-session:hover {
    color: var(--zumba-white);
}
.ht-user-session.gh-profile-nav-name:after {
    transform: rotate(90deg);
    top: 0.3em;
}
.ht-user-session .chevron {
    margin-left: 0.25em;
    position: inherit;
    right: inherit;
    top: inherit;
    display: inline-block;
}
.ht-user-session .chevron.down::before {
    border-width: 2px 2px 0 0;
    height: 8px;
    width: 8px;
    top: 0;
    transform: rotate(135deg);
    content: '';
    border-style: solid;
    display: inline-block;
    left: 0.15em;
    position: relative;
    vertical-align: top;
}
.ht-user-session .chevron.white::before {
    border-color: var(--zumba-white);
}
.ht-user-session:hover + .dropdown-mtv {
    display: block;
}

.dropdown-mtv li a {
    font-weight: normal;
    font-family: "Zandes-Medium", Helvetica, Arial, sans-serif;
}
.dropdown-mtv {
    padding: 0.5em 0;
    border: 0;
    background: #d43361;
    z-index: 9999;
    margin-top: 1em;
    text-align: left;
    width: 12.5em;
    left: 0;
    top: 1.75em !important;
    display: none;
    position: absolute;
    list-style: none;
    margin-left: 0;
    max-height: none;
    height: auto;
    margin-top: 2px;
}
.dropdown-mtv:before {
    content: '';
    top: -1em;
    border: solid 8px;
    border-color: transparent transparent #d43361 transparent;
    position: absolute;
    left: 1em;
}
.dropdown-mtv:after {
    content: '';
    position: absolute;
    left: 0;
    top: -1em;
    padding: 0.6em 0;
    display: block;
    width: 100%;
}
.dropdown-mtv:hover {
    display: block;
}
.dropdown-mtv.dd-right {
    left: initial !important;
    right: 0;
}
.dropdown-mtv.dd-right:before {
    left: initial;
    right: 1em;
}
.dropdown-mtv li a {
    padding: 1em 1.25em;
    font-size: 0.8125rem;
    color: #1d1d24;
    text-transform: uppercase;
    line-height: 1em;
    display: block;
}
.dropdown-mtv li a:hover {
    background: var(--zumba-gray-400);
}
.global-header .dropdown-mtv {
    background: #d43361;
    -webkit-border-radius: 0.2em;
    -moz-border-radius: 0.2em;
    -ms-border-radius: 0.2em;
    -o-border-radius: 0.2em;
    border-radius: 0.2em;
}
.global-header .dropdown-mtv:before {
    border-color: transparent transparent #d43361 transparent;
}
.global-header .dropdown-mtv li a {
    font-family: var(--zumba-font-default);
    font-size: 0.8125rem;
    color: var(--zumba-white);
    cursor: pointer;
}
.global-header .dropdown-mtv li a:hover {
    background: var(--zumba-gray-800);
}

.header-toggle.ht-strong::after {
    top: -9px !important;
}
.header-toggle.ht-zumba::after {
    top: -9px !important;
}
.header-toggle.ht-secondary-nav {
    margin: .4em 1em 0 0 !important;
}
.header-toggle {
    background: #2f3337;
    display: none;
}
.header-toggle::after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: '';
    clear: both;
    height: 0;
}
.header-toggle .brands {
    float: left;
}
.header-toggle .brands a {
    cursor: pointer;
}
.header-toggle .brands .ht-brand {
    text-indent: -9999px;
    float: left;
    overflow: hidden;
    height: 2.1875em;
    padding: 0 1em;
    display: flex;
    align-items: center;
    justify-content: center;
}
.header-toggle .brands .ht-brand::after {
    content: '';
    opacity: 0.5;
}
.header-toggle .brands .ht-brand:hover {
    background: #212427;
}
.header-toggle .brands .ht-brand.active {
    background: #212427;
    border: none;
    font-size: initial;
}
.header-toggle .brands .ht-brand.active::after {
    opacity: 1;
}
.header-toggle .ht-strong::after {
    display: block;
    position: relative;
    display: block;
    position: relative;
    background: url("~@icons/header-toggle-sprite-2021-n.svg?external") no-repeat;
    background-size: 108px 177px;
    background-position: 0 62.11180124223603%;
    width: 108px;
    height: 16px;
}
.header-toggle .ht-strong-nation::after {
    display: block;
    position: relative;
    display: block;
    position: relative;
    background: url("~@icons/header-toggle-sprite-2021-n.svg?external") no-repeat;
    background-size: 108px 177px;
    background-position: 0 74.35897435897436%;
    width: 61px;
    height: 21px;
}
.header-toggle .ht-zumba::after {
    display: block;
    position: relative;
    background: url("~@icons/header-toggle-sprite-2021-n.svg?external") no-repeat;
    background-size: 108px 177px;
    background-position: 0 86.7088607595%;
    width: 51px;
    height: 19px;
}
.header-toggle .ht-zumbini::after {
    display: block;
    position: relative;
    top: -2px;
    background: url('~@icons/header-toggle-sprite-2021-n.svg?external') no-repeat;
    background-size: 108px 177px;
    background-position: 0 100%;
    width: 68px;
    height: 21px;
}
.header-toggle .ht-secondary-nav {
    margin-top: 0.75em;
}
.header-toggle .ht-secondary-nav-item {
    margin-left: 1.5em;
    position: relative;
}
.header-toggle .ht-secondary-nav-item a:hover {
    text-decoration: none;
}
.lte9 .header-toggle .ht-secondary-nav-item {
    float: left;
    margin-left: 30px;
}
.header-toggle .ht-user-session {
    display: none;
    line-height: 0.9em;
}
.header-toggle .ht-user-session:hover {
    color: var(--zumba-white);
}
.header-toggle .ht-user-session.gh-profile-nav-name:after {
    top: 0.3em;
}
.header-toggle .ht-user-session .chevron {
    margin-left: 0.25em;
    position: inherit;
    right: inherit;
    top: inherit;
    display: inline-block;
}
.header-toggle .ht-user-session .chevron.down::before {
    border-width: 2px 2px 0 0;
    height: 8px;
    width: 8px;
    top: 0;
    content: '';
    border-style: solid;
    display: inline-block;
    left: 0.15em;
    position: relative;
    vertical-align: top;
}
.header-toggle .ht-user-session .chevron.white::before {
    border-color: var(--zumba-white);
}
.header-toggle .ht-user-session:hover + .dropdown-mtv {
    display: block;
}
.header-toggle .dropdown-mtv {
    background: var(--zumba-gray-100);
}
.header-toggle .dropdown-mtv:before {
    border-color: transparent transparent var(--zumba-gray-100) transparent;
}
.header-toggle .dropdown-mtv a {
    font-size: 14px;
}

.header-toggle-icon_ht-bag, .header-toggle-icon_ht-bag-drop, .header-toggle-icon_ht-gear, .header-toggle-icon_ht-strong, .header-toggle-icon_ht-strong-nation, .header-toggle-icon_ht-zumba, .header-toggle-icon_ht-zumbini {
    background: url('~@icons/header-toggle-sprite-2021-n.svg?external') no-repeat;
    background-size: 108px 177px;
}
.header-toggle-icon_ht-bag {
    background-position: 0 0;
    width: 49px;
    height: 34px;
}
.header-toggle-icon_ht-bag-drop {
    background-position: 0 25.5639097744%;
    width: 55px;
    height: 44px;
}
.header-toggle-icon_ht-gear {
    background-position: 0 50.3225806452%;
    width: 22px;
    height: 22px;
}
.header-toggle-icon_ht-strong {
    background-position: 0 62.1118012422%;
    width: 108px;
    height: 16px;
}
.header-toggle-icon_ht-strong-nation {
    background-position: 0 74.358974359%;
    width: 61px;
    height: 21px;
}
.header-toggle-icon_ht-zumba {
    background-position: 0 86.7088607595%;
    width: 51px;
    height: 19px;
}
.header-toggle-icon_ht-zumbini {
    background-position: 0 100%;
    width: 68px;
    height: 21px;
}
@media only screen and (min-width:64em) {
    .header-toggle .ht-secondary-nav {
        margin: 0.72em 1em 0 0;
        float: right;
    }
    .header-toggle .ht-user-session {
        display: inherit !important;
        position: relative;
        padding-right: 0.9em;
        color: var(--zumba-white);
        font-size: 14px;
        cursor: pointer;
        font-style: normal;
        font-weight: normal;
        font-family: "Zandes-Medium", Helvetica, Arial, sans-serif;
    }
    .header-toggle .ht-user-session:after {
        content: none;
    }
    .header-toggle {
        display: block;
        margin: 0;
        padding: 0;
    }
    .ht-secondary-nav {
        margin: .72em 1em 0 0;
        float: right;
    }
    .ht-user-session:after {
        content: none;
    }
}
</style>
