<template>
    <Modal
        :show="state.displayModal"
        show-close-button
        mobile-drawer
        close-on-mask
        style="z-index: 9999"
        @closed="onCloseRatingModal"
    >
        <div class="rating-modal-content">
            <div v-if="modalMode === 'rate' && !loading">
                <h4
                    class="rating__title f-inter"
                >
                    {{ modalTitle }}
                </h4>
                <p class="rating-modal__date">
                    {{ huminizedClassDate }}
                </p>
                <Rating
                    class="rating"
                    :star-size="32"
                    @rating:rated="onRatingHandler($event)"
                />
                <a
                    v-if="state.rating < 1 && enableDidnotAttend"
                    href="#"
                    class="rating-modal-content__did-not-attend"
                    @click="$emit('did-not-attend')"
                >
                    {{ t.didNotAttendThisClass }}
                </a>
                <HorizontalSeparator v-if="state.rating" />
                <Feedback
                    v-if="state.rating >= 1 && state.rating <= 3"
                    :feedback-labels-options="feedbackLabelsOptions"
                    @feedback:submitted="onNegativeFeedback($event)"
                />
                <div
                    v-else-if="state.rating >= 4 && state.rating <= 5"
                    class="review-section"
                >
                    <p class="p-medium review-section__header">
                        <b>{{ t.leaveOptionalReview }}</b>
                    </p>
                    <p class="p-caption review-section__body">
                        {{ t.reviewBody }}
                    </p>
                    <TextArea
                        v-model="state.positiveComment"
                        :rows="5"
                        :maxlength="255"
                        :label="t.reviewPlaceHolder"
                        name="comment"
                        @focus="scrollTextIntoView"
                    />
                    <ZButton
                        class="review-section__submit"
                        primary
                        @click.prevent="onPositiveFeedback"
                    >
                        {{ t.submit }}
                    </ZButton>
                    <div class="cancel-section">
                        <a
                            class="cancelTip"
                            @click.prevent="onCloseRatingModal"
                        >
                            {{ t.cancel }}
                        </a>
                    </div>
                </div>
            </div>
            <PayPalTipHandler
                v-else-if="modalMode === 'tip' && !loading"
                :t="t"
                :instructor-name="instructorName"
                :currencies="currencies"
                :currency="currency"
                :instructor-pid="instructorPid"
                :store-tip-url="storeTipUrl"
                :mark-tip-url="markTipUrl"
                :gift-source="giftSource"
                :tip-data-action="`${tipDataBaseAction}/${instructorPid}`"
                :async-contact-retrieval="asyncContactRetrieval"
                @closed="onCloseRatingModal"
                @price:focus="scrollTextIntoView"
                @toast:message="$emit('tip:send')"
            />
            <div v-else-if="loading">
                <Loading />
            </div>
        </div>
    </Modal>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, PropType, ref } from 'vue'
import Modal from '@components/Core/Modal.vue'
import Rating from '@modules/Rating/Rating.vue'
import Feedback, { FeedbackState } from '@modules/Rating/Feedback.vue'
import HorizontalSeparator from '@components/Core/HorizontalSeparator.vue'
import { validateTranslations } from "@ts/Util/i18n";
import { interpolate } from "@ts/Util/text";
import { feedbackLabelOption } from "@modules/Rating/RatingHandler.vue";
import PayPalTipHandler from "@components/Buy/PayPalTipHandler.vue";
import { DEFAULT_CURRENCY, paypalSupportedCurrencies } from "@ts/Util/currency";
import TextArea from "@components/Core/TextArea.vue";
import ZButton from "@components/Core/ZButton.vue";
import { useSafariMobileKeyboard } from "@composables/safariMobileKeyboard";
import Loading from '@components/Util/Loading.vue'

const localT = {
    modalTitle: 'How was your class with {0}?',
    feedbackHeader: 'What could have been better?',
    whatHappened: 'Tell us what happened...',
    submitFeedback: 'Submit Feedback',
    didNotAttendThisClass: 'I did not attend this class',
    at: 'at',
    tipTitle: 'Send tip to',
    tipSubtitle: 'Show some love to your instructor by sending a tip',
    tipAmountPlaceholder: 'Enter tip amount',
    sendTip: 'Send tip',
    cancel: 'Cancel',
    leaveOptionalReview: 'Leave a Review (Optional)',
    reviewBody: 'Share a review about this instructor.',
    reviewPlaceHolder: 'How was their class, their coaching style?',
    submit: 'Submit',
};

export type RateClassData = {
    rating: number,
    feedback: FeedbackState
    closeModal: boolean
}

type RateClassMode = 'rate' | 'tip'

export default defineComponent({
    name: 'RateClass',
    components: {
        ZButton,
        TextArea,
        Modal,
        Rating,
        Feedback,
        HorizontalSeparator,
        PayPalTipHandler,
        Loading
    },
    props: {
        t: {
            type: Object,
            default: () : Object => localT,
            validator: (value : Object) => validateTranslations(value, localT),
        },
        instructorName: {
            type: String,
            required: true
        },
        instructorEmail: {
            type: String,
            required: true
        },
        /**
         * Class date object. This component is not responsible for determining
         * the type of  class and displaying the time based on it.
         */
        classDate: {
            type: Object as PropType<Date>,
            required: true,
        },
        show: {
            type: Boolean,
            default: false,
        },
        feedbackLabelsOptions: {
            type: Array as PropType<Array<feedbackLabelOption>>,
            required: true,
        },
        tippingEnabled: {
            type: Boolean,
            required: true,
        },
        currencies: {
            type: Array as PropType<Array<string>>,
            default: () : Array<string> => paypalSupportedCurrencies
        },
        currency: {
            type: String,
            default: DEFAULT_CURRENCY
        },
        instructorPid: {
            type: String,
            default: '',
        },
        storeTipUrl: {
            type: String,
            default: ''
        },
        markTipUrl: {
            type: String,
            default: ''
        },
        modalMode: {
            type: String,
            default: 'rate'
        },
        loading: {
            type: Boolean,
            default: false
        },
        enableDidnotAttend: {
            type: Boolean,
            default: true
        },
        giftSource: {
            type: String,
            default: 'post-class'
        },
        tipDataBaseAction: {
            type: String,
            default: ''
        },
        asyncContactRetrieval: {
            type: Boolean,
            default: true
        }
    },
    emits: [
        'close',
        'rating:rated',
        'did-not-attend',
        'feedback:submitted',
        'modal:close',
        'tip:send',
        'modal-mode:change'
    ],
    setup (props, ctx) {
        const state = reactive({
            displayModal: ref(props.show),
            rating: 0,
            feedback: {},
            positiveComment: '',
            submitting: false,
        })

        const modalTitle = computed<string>((): string => {
            return interpolate(props.t.modalTitle, [props.instructorName])
        })

        const onRatingHandler = (rate: number) => {
            state.rating = rate
            ctx.emit('rating:rated', rate)
        }

        const onNegativeFeedback = (feedback: FeedbackState) => {
            state.feedback = feedback
            ctx.emit('feedback:submitted', {
                rating: state.rating,
                feedback,
                closeModal: true,
            } as RateClassData)
        }

        const onPositiveFeedback = () => {
            const showTip = Boolean(props.instructorEmail) &&
                props.tippingEnabled
            ctx.emit('feedback:submitted', {
                rating: state.rating,
                feedback: {
                    comment: state.positiveComment,
                    selectedFeedbackLabels: {},
                },
                closeModal: !showTip,
            } as RateClassData)
            if (showTip) {
                ctx.emit('modal-mode:change', 'tip')
                return
            }
        }

        const onCloseRatingModal = () => {
            ctx.emit('modal:close', state.rating)
        }

        const { scrollTextIntoView } = useSafariMobileKeyboard()

        return {
            state,
            modalTitle,
            onRatingHandler,
            onNegativeFeedback,
            onPositiveFeedback,
            onCloseRatingModal,
            scrollTextIntoView,
        }
    },
    computed: {
        huminizedClassDate():string {
            if (!this.classDate) {
                return ''
            }
            // request a weekday along with a long date
            const dateOptions: Intl.DateTimeFormatOptions = {
                weekday: 'long',
                month: 'long',
                day: 'numeric',
            }

            const timeOptions: Intl.DateTimeFormatOptions = {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
            }
            const dateFormatted = new Intl.DateTimeFormat(window.navigator.language, dateOptions)
                .format(this.classDate)
            const timeFormatted = new Intl.DateTimeFormat(window.navigator.language, timeOptions)
                .format(this.classDate)

            return `${dateFormatted} ${this.t.at} ${timeFormatted}`
        },
    },
    watch: {
        show: function(newVal) {
            this.state.displayModal = newVal
            if (newVal === false) {
                this.state.rating = 0
                this.state.feedback = {}
            }
        }
    },
})
</script>

<style scoped>
.rating-modal-content {
    box-sizing: border-box;
}

.cancel-section {
    padding-bottom: .375rem;
}

.rating-modal-content__did-not-attend {
    display: block;
    margin: 2.25rem auto 1rem;
    color: var(--zumba-pink);
    text-align: center;
    text-decoration: none;
}

@media screen and (min-width: 48rem) {
    .rating-modal-content {
        width: 34.25rem;
        padding: 1rem 3rem;
    }

    .cancel-section {
        padding-bottom: 0;
    }

    .rating-modal-content__did-not-attend {
        margin: 1.75rem auto 1rem;
    }
}

.rating__title {
    font-size: 1.25rem;
    margin-bottom: 1rem;
    font-weight: 700;
    text-align: center;
}


.rating {
    text-align: center;
    margin-top: 1rem;
}

.rating-modal__date {
    text-align: center;
    margin-top: 0.5rem;
}
.skip-for-now {
    text-align: center;
    display: block;
    margin-top: 1.25rem;
}

.review-section {
    text-align: center;
}
.review-section__header {
    margin-bottom: 1rem;
    padding-bottom: 0;
}
.review-section__body {
    margin-bottom: 1rem;
}
.review-section__submit {
    margin-top: .75rem;
}
.cancel-section {
    color: var(--zumba-light-pink);
    text-align: center;
    margin-top: 1rem;
    cursor: pointer;
}
</style>
