<template>
    <aside
        class="gf-email-subscribe"
    >
        <h3 class="gf-footer-nav-title gf-email-subscribe-title">
            {{ props.t.title }}
        </h3>
        <form
            ref="newsletterForm"
            class="gf-email-subscribe-form"
            @submit.prevent="onSubmit"
        >
            <div
                v-if="state.subscribed"
                class="gf-email-subscribe-success"
            >
                <span>{{ props.t.thankYouMessage }}</span>
            </div>
            <div
                v-if="!state.subscribed"
                class="gf-email-subscribe-wrapper"
            >
                <ZInput
                    class="gf-email-subscribe-input"
                    :label="props.t.inputPlaceholder"
                    type="email"
                    input-id="email"
                    name="email"
                    required
                    @invalid="() => state.error = true"
                />
                <ZButton 
                    class="gf-email-subscribe-z-button" 
                    data-ga4-event-name="subscribe_lead"
                    data-ga4-action="Join Click"
                >
                    {{ props.t.buttonText }}
                </ZButton>
            </div>
            <div
                v-if="state.error"
                class="gf-email-subscribe-error"
            >
                {{ props.t.invalidEmailText }}
            </div>
            <p class="gf-unsubscribe-notice">
                {{ props.t.unsubscribeNoticeText }}
            </p>
        </form>
    </aside>
</template>

<script setup lang="ts">
import {reactive, Ref, ref} from 'vue'
import {post} from '@ts/Util/http'
import {isoCountryCode, prefixLocaleURL} from '@ts/Util/i18n'
import ZInput from '@components/Core/ZInput.vue'
import ZButton from '@components/Core/ZButton.vue'
import { csrfToken } from '@ts/Util/security'
import { dataLayer } from '@ts/Util/analytics'

const newsletterForm: Ref = ref('newsletterForm')

const state = reactive({
    subscribed: false,
    error: false
})

const props = defineProps({
    source: {
        type: String,
        default: 'site-footer',
    },
    t: {
        type: Object,
        default: () => ({
            title: 'Get the latest Zumba News, Promos & Events.',
            thankYouMessage: 'Thanks for Joining!',
            inputPlaceholder: 'Enter your email',
            buttonText: 'Join',
            invalidEmailText: 'The email address you entered is invalid.',
            unsubscribeNoticeText: 'You can unsubscribe at any time.'
        })
    }
})

const onSubmit = async (e) => {
    const email = e.target.elements['email'].value
    state.error = false
    const response = await post(
        prefixLocaleURL('/account/maillist_subscribe'),
        csrfToken(),
        {
            email: email,
            source: props.source,
        }
    )

    dataLayer.push({
        event: 'gaEvent',
        category: 'Cat',
        action: 'Signup',
        label: isoCountryCode()
    })

    if (response.ok) {
        state.subscribed = true
        e.target.elements['email'].value = ''
    } else {
        state.error = true
    }
}
</script>

<style scoped>
/** Common */
.gf-email-subscribe-title {
    text-align: center;
    color: #fff;
    margin-bottom: 1em;
}

.gf-email-subscribe-form {
    margin: 0.5em auto 2em;
}

.gf-email-subscribe-error {
    color: #d43361;
    font-size: 1rem;
    margin-bottom: 1em;
    padding: 0.5em;
}

.gf-email-subscribe-wrapper {
    position: relative;
    display: flex;
}

.gf-email-subscribe-input {
    width: 70%;
    height: 49px;
    padding: 0;
}

.gf-email-subscribe-input :deep(.zInput__input) {
    background-color: var(--zumba-white);
    border: none;
    margin-bottom: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.gf-email-subscribe-input :deep(.zInput__input:not(.icon):focus) {
    border-left: none;
}

.gf-email-subscribe-z-button {
    width: 30%;
    height: 47px;
}

.gf-email-subscribe-z-button :deep(.z-button) {
    background-color: var(--zumba-dark-coral);
    color: var(--zumba-gray-100);
    border-color: var(--zumba-dark-coral);
}

.gf-email-subscribe-z-button :deep(.z-button:hover) {
    background-color: var(--zumba-gray-800);
    border-color: var(--zumba-gray-800);
    color: var(--zumba-white);
}

.gf-email-subscribe-success {
    background: url('~@icons/check.svg?external') 95% center no-repeat #dae251;
    font-size: 1rem;
    text-transform: uppercase;
    font-family: zandes-bold,Helvetica,Arial,sans-serif;
    letter-spacing: .025em;
    padding: 1.375em 0.75em 1em;
    -webkit-border-radius: .2em;
    -moz-border-radius: .2em;
    -ms-border-radius: .2em;
    -o-border-radius: .2em;
    border-radius: 0.2em;
}

.gf-unsubscribe-notice {
    color: #95959a;
    clear: both;
    margin-top: 0.8em;
    margin-bottom: 1.5em;
    background: none;
    text-indent: 0;
}

@media only screen and (min-width: 48rem) {
    .gf-footer-nav-title {
        display: block;
        text-align: left;
    }

    .gf-email-subscribe-form {
        margin: 0.5em 0 2em;
        padding: 0;
    }
}

/** Zumba */
.theme-zumba .gf-email-subscribe-form {
    padding: 0 1em;
}

@media only screen and (min-width: 48rem) {
    .theme-zumba .gf-email-subscribe-form {
        padding: 0;
    }
}

/** Strong */
.theme-strong .gf-email-subscribe * {
    font-family: var(--strong-font-default);
}

.theme-strong .gf-email-subscribe-title {
    font-size: 1em !important;
    font-weight: 800;
}

.theme-strong .gf-unsubscribe-notice {
    color: #878ea0;
    font-weight: 500;
}
</style>
