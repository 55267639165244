<template>
    <footer
        :class="footerStyleClasses"
    >
        <div class="gf-footer-nav">
            <div class="row">
                <div
                    v-if="theme === 'strong'"
                    class="columns gf-footer-strong-logo"
                >
                    <a
                        href="#"
                        class="strong-link"
                    ><StrongNationLogoSVG class="strong-logo" /></a>
                </div>
                <div class="columns medium-8 large-9 gf-footer-nav-columns">
                    <FooterNavList
                        v-for="(navList, key) in props.navLists"
                        v-bind="navList"
                        :key="key"
                        :theme="theme"
                    />
                </div>
                <div
                    v-if="props.theme === 'zumba'"
                    class="
                        gf-footer-nav-app-stores
                        hide-for-medium-up"
                >
                    <a
                        href="https://apps.apple.com/app/zumba-the-dance-fitness-party/id6447769025"
                    ><AppStoreMobileLogoSVG class="gf-footer-app-store-logo" /></a>
                    <a
                        href="https://play.google.com/store/apps/details?id=com.zumba.consumerapp"
                    ><GooglePlayMobileLogoSVG class="gf-footer-google-play-logo" /></a>
                </div>
                <div class="medium-4 large-3 columns gf-footer-social">
                    <aside class="gf-social">
                        <h3
                            v-if="theme === 'zumba'"
                            class="
                                gf-footer-nav-title gf-social-title
                                hide-for-small
                                z-b3
                            "
                        >
                            {{ props.t.socialTitle }}
                        </h3>
                        <nav class="gf-social-wrapper">
                            <a
                                v-for="(socialItem, key) in props.socialList"
                                :key="key"
                                :href="socialItem.link"
                                rel="noopener"
                                target="_blank"
                                data-ga4-event-name="social_cta_click"
                                :data-ga4-action="socialItem.gaAction"
                            ><component
                                :is="socialComponents[socialItem.icon]"
                            /></a>
                        </nav>
                    </aside>
                    <FooterNewsletterForm
                        v-if="!authenticated"
                        :source="newsletterSource"
                        :t="{
                            title: props.t.newsletter.title,
                            thankYouMessage: props.t.newsletter.thankYouMessage,
                            inputPlaceholder:
                                props.t.newsletter.inputPlaceholder,
                            buttonText: props.t.newsletter.buttonText,
                            invalidEmailText:
                                props.t.newsletter.invalidEmailText,
                            unsubscribeNoticeText:
                                props.t.newsletter.unsubscribeNoticeText,
                        }"
                    />
                    <aside class="brands">
                        <p>{{ props.t.brandsText }}:</p>
                        <a
                            href="//zumba.com"
                            title="Zumba"
                        ><FooterZumbaBrandSVG /></a>
                        <a
                            href="//strongnation.com"
                            title="Strong Nation"
                            target="_blank"
                            rel="noopener"
                        ><FooterStrongNationBrandSVG /></a>
                        <a
                            href="//zumbawear.com/"
                            title="Zumba Wear"
                            target="_blank"
                            rel="noopener"
                        ><FooterWearZumbaBrandSVG /></a>
                        <a
                            href="//zumbawear.com/pages/strong-id-homepage?brand=strong"
                            title="Strong ID"
                            target="_blank"
                            rel="noopener"
                        ><FooterStrongIDBrandSVG /></a>
                    </aside>
                </div>
            </div>
        </div>
    </footer>
</template>

<script setup lang="ts">
import { HeaderFooterStyle } from "@ts/Layout/header-footer";

export type SocialList = {
    icon: string,
    link: string,
    gaAction: string
}

import { onMounted, ref, Ref } from 'vue';
import { computed, PropType } from "vue";
import FooterNavList, {FooterNavListProps} from '@components/Footer/FooterNavList.vue';
import FooterNewsletterForm from '@components/Footer/FooterNewsletterForm.vue';
import FooterStrongNationBrandSVG from '@icons/footer/strong-nation-brand.svg'
import FooterZumbaBrandSVG from '@icons/footer/zumba-brand.svg'
import FooterStrongIDBrandSVG from '@icons/footer/strong-id-brand.svg'
import FooterWearZumbaBrandSVG from '@icons/footer/wear-zumba-brand.svg'
import FacebookIconSVG from '@icons/footer/facebook-icon.svg'
import InstagramIconSVG from '@icons/footer/instagram-icon.svg'
import PinterestIconSVG from '@icons/footer/pinterest-icon.svg'
import TiktokIconSVG from '@icons/footer/tiktok-icon.svg'
import TwitterIconSVG from '@icons/footer/twitter-icon.svg'
import YoutubeIconSVG from '@icons/footer/youtube-icon.svg'
import StrongNationLogoSVG from '@icons/strong-nation-logo.svg'
import AppStoreMobileLogoSVG from '@icons/footer/app-store-mobile.svg'
import GooglePlayMobileLogoSVG from '@icons/footer/google-play-mobile.svg'
import { isUserAuthenticated } from '@ts/Util/security';

const props = defineProps({
    theme: {
        type: String,
        default: 'zumba'
    },
    t: {
        type: Object,
        default: () =>({
            brandsText: 'Our brands',
            socialTitle: 'Let\'s Be Friends',
            newsletter: {
                title: 'Get the latest Zumba News, Promos & Events.',
                thankYouMessage: 'Thanks for Joining!',
                inputPlaceholder: 'Enter your email',
                buttonText: 'Join',
                invalidEmailText: 'The email address you entered is invalid.',
                unsubscribeNoticeText: 'You can unsubscribe at any time.'
            }
        })
    },
    navLists: {
        type : Array<FooterNavListProps>,
        default: []
    },
    socialList: {
        type: Array<SocialList>,
        default: []
    },
    newsletterSource: {
        type: String,
        default: 'site-footer',
    },
    headerFooterStyle: {
        type: String as PropType<HeaderFooterStyle>,
        default: 'full'
    },
})

const authenticated: Ref<boolean> = ref(false)

const socialComponents = {
    FacebookIconSVG,
    InstagramIconSVG,
    PinterestIconSVG,
    TiktokIconSVG,
    TwitterIconSVG,
    YoutubeIconSVG
}

onMounted(() => {
    authenticated.value = isUserAuthenticated()
})

const footerStyleClasses = computed((): Object => {
    const styles = {
        'hide-nav-ele': props.headerFooterStyle === 'minimal'
    }
    styles[props.theme] = true
    return styles;
})
</script>

<style scoped>
/* Common */
.gf-footer-nav {
    overflow: hidden;
    padding: 1.5em 0 0;
    background: #2c2d37;
    z-index: 1000;
    position: relative;
}

.gf-footer-nav-columns {
    display: none;
}

.gf-social-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.85em;
}

.gf-social {
    margin-bottom: 1em;
}

.gf-social .gf-footer-nav-title {
    display: none;
}

.brands {
    display: none;
}

.brands p {
    color: #95959a;
    font-size: 0.875rem;
    margin-bottom: 0.75em;
    background: none;
    text-indent: 0;
    font-weight: 500;
    font-family: var(--zumba-font-default);
}

@media only screen and (min-width: 48em) {
    .gf-footer-nav-columns {
        display: flex;
        flex-wrap: wrap;
    }

    .gf-social-wrapper {
        justify-content: left;
        gap: .8em;
    }

    .gf-social .gf-footer-nav-title {
        display: block;
        font-size: 1.1875rem;
    }

    .brands {
        display: flex;
        flex-direction: column;
        gap: .5em;
    }

    .gf-social {
        margin-bottom: 2em;
    }
}

@media only screen and (min-width: 64em) {
    .gf-footer-nav-columns {
        display: flex;
        flex-wrap: wrap;
        gap: .3125em;
    }

    .gf-social-wrapper {
        gap: 1.15em;
    }

    .gf-footer-nav {
        padding: 2.2em 0;
    }
}

/* Strong */
.theme-strong .gf-footer-nav * {
    font-family: var(--strong-font-default);
}

.theme-strong .gf-footer-nav {
    background: #272a38;
}

.theme-strong .strong-logo {
    display: none;
}

.theme-strong .gf-footer-social {
    padding: 0 .9375rem;
}

.theme-strong .brands p {
    color: #878ea0;
    font-weight: 500;
}

@media only screen and (min-width: 48em) {
    .theme-strong .strong-logo {
        display: block;
        margin-left: 0.5em;
        width: 9.05em;
    }

    .theme-strong .gf-footer-strong-logo,
    .theme-strong .gf-footer-social {
        width: 12.6em;
    }

    .theme-strong .gf-footer-nav-columns {
        width: 22.75em;
    }

    .theme-strong .gf-social-wrapper {
        gap: 1.3rem;
    }

    .theme-strong .gf-footer-nav {
        padding: 1.5em 0;
    }
}

@media only screen and (min-width: 64em) {
    .theme-strong .gf-footer-strong-logo,
    .theme-strong .gf-footer-social {
        width: 16em;
    }

    .theme-strong .gf-footer-nav-columns {
        width: 32em;
        justify-content: flex-end;
    }

    .theme-strong .strong-logo {
        margin-left: 2em;
    }
}

@media only screen and (min-width: 76.875rem) {
    .theme-strong .gf-footer-strong-logo,
    .theme-strong .gf-footer-social {
        width: 19.21em !important;
    }

    .theme-strong .gf-footer-nav-columns {
        width: 37.1875em !important;
    }
}
.gf-footer-nav-app-stores {
  width: 100%;
  height: 79px;
  padding: 10px 0;
  text-align: center;
  flex-shrink: 0;
}

.gf-footer-app-store-logo,
.gf-footer-google-play-logo {
  display: inline-block;
  width: auto;
  height: 40px;
  margin-right: 18px;
}
</style>
