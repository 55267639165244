<template>
    <nav class="gf-footer-nav-set z-b3">
        <h3 class="gf-footer-nav-title">
            {{ props.title }}
        </h3>
        <ul
            v-for="(item, key) in list"
            :key="key"
            class="gf-footer-nav-list"
        >
            <li class="gf-footer-nav-item">
                <a
                    class="gf-footer-nav-link"
                    :href="item.link"
                    data-ga4-event-name="footer_nav"
                    :data-ga4-action="item.text"
                >
                    {{ item.text }}
                </a>
            </li>
        </ul>
        <div
            v-if="props.title ==='Classes' && props.theme === 'zumba'"
            class="gf-footer-nav-app-stores"
        >
            <a
                href="https://apps.apple.com/app/zumba-the-dance-fitness-party/id6447769025"
            ><AppStoreLogoSVG /></a>
            <a
                href="https://play.google.com/store/apps/details?id=com.zumba.consumerapp"
            ><GooglePlayLogoSVG /></a>
        </div>
    </nav>
</template>

<script setup lang="ts">
import AppStoreLogoSVG from '@icons/footer/app-store.svg'
import GooglePlayLogoSVG from '@icons/footer/google-play.svg'

export type ListItem = {
    text: string,
    link: string
}
export type FooterNavListProps = {
    title: string,
    list: Array<ListItem>
}

const props = defineProps<FooterNavListProps & {
    theme?: string;
}>();
</script>

<style scoped>
/* Common */
.gf-footer-nav-set {
    display: flex;
    flex-direction: column;
}

.gf-footer-nav-set a {
    text-decoration: none;
}

.gf-footer-nav-set ul {
    list-style: none;
}

.gf-footer-nav-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    padding: 0;
    font-size: 1rem;
}

.gf-footer-nav-list .gf-footer-nav-link {
    display: block;
    margin-bottom: .5em;
    color: var(--zumba-white);
    font-weight: 400;
    font-size: 1rem;
}

.gf-footer-nav-app-stores {
    margin-top:8px;
}

@media only screen and (min-width: 48rem) {
    .gf-footer-nav-set {
        margin: 0 0 2em;
        padding: 0 .625rem;
    }
}

@media only screen and (min-width: 90rem) {
    .gf-footer-nav-set {
        width: 10.62em;
    }
}

/* Zumba */
@media only screen and (min-width: 48rem) {
    .theme-zumba .gf-footer-nav-set {
        width: 33%;
    }
}

@media only screen and (min-width: 64rem) {
    .theme-zumba .gf-footer-nav-set {
        width: 19.4%;
    }
}

@media only screen and (min-width: 90rem) {
    .theme-zumba .gf-footer-nav-set {
        width: 10.7em;
    }
}

/* Strong */
.theme-strong .gf-footer-nav-set * {
    font-family: var(--strong-font-default) !important;
    font-weight: 400;
}

.theme-strong .gf-footer-nav-title,
.theme-strong .gf-footer-nav-link {
    font-size: .875rem !important;
    line-height: 1.6;
}

.theme-strong .gf-footer-nav-title {
    font-weight: 800;
}

@media only screen and (min-width: 48rem) {
    .theme-strong .gf-footer-nav-set {
        width: 10.75em;
    }
}

@media only screen and (min-width: 64rem) {
    .theme-strong .gf-footer-nav-set {
        width: 7.45em;
    }
}

@media only screen and (min-width: 76.875rem) {
    .theme-strong .gf-footer-nav-set {
        width: 8.04em;
    }
}
 </style>
