.gf-footer-nav-title {
    font-weight: bold;
    text-transform: uppercase;
    color: var(--zumba-white);
    font-size: 1.375rem !important;
    margin: .2em 0 1em 0;
    line-height: 1.4em !important;
}

@media only screen and (min-width: 48em) {
    .gf-footer-nav-title {
        font-size: 1.1875rem !important;
        margin-bottom: 0.5em;
    }
}

@media only screen and (min-width: 64em) {
    .gf-footer-nav-title {
        font-size: 1rem !important;
        margin-bottom: 1em;
    }
}
