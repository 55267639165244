<template>
    <div
        class="zInput"
        :class="{
            'zInput__error': state.hasError,
            'is-safari': isSafari()
        }"
    >
        <div class="input-container">
            <input
                :id="inputId"
                ref="input"
                class="zInput__input"
                :class="{
                    'with-icon' : hasIcon
                }"
                :value="modelValue"
                :name="name"
                :type="type"
                :placeholder="placeholder || ' '"
                :maxlength="maxlength"
                :min="min"
                :max="max"
                :required="required"
                :disabled="disabled"
                :autocomplete="autocomplete"
                :readonly="readonly"
                @input="onInput"
                @focus="onFocus"
                @invalid="onInvalid($event)"
                @blur="onBlur($event)"
                @change="onChange($event)"
            >
            <label
                v-if="showLabel"
                :for="name"
                class="zInput__label z-b4"
                @click="onClick"
            >
                {{ label }}
                <span
                    v-if="required && showRequired"
                    class="required-star"
                >
                    *
                </span>
            </label>
            <div
                v-if="hasIcon"
                :class="[
                    'zInput__icon--container',
                    {
                        'zInput__error': state.hasError,
                        'with-label': showLabel
                    }
                ]"
            >
                <slot
                    class="icon"
                    name="icon"
                />
            </div>
            <div
                v-if="$slots['right-slot']"
                class="zInput__right-slot"
            >
                <slot name="right-slot" />
            </div>
        </div>
        <!-- eslint-disable vue/no-v-html-->
        <span
            v-if="state.hasError"
            class="zInput__error"
            v-html="error"
        />
    </div>
</template>

<script lang="ts" setup>
import { PropType, reactive, computed, useSlots, ref, watch, Ref } from 'vue'
import { isSafari } from '@ts/Util/responsiveness'

const props = defineProps({
    label: {
        type: String,
        default: ""
    },
    showLabel: {
        type: Boolean,
        default: true
    },
    inputId: {
        type: String,
        default: null
    },
    name: {
        type: String,
        required: true
    },
    modelValue: {
        type: [ String, Number ],
        default: ''
    },
    type: {
        type: String as PropType<'text' | 'email' | 'phone' | 'password' | 'number' | 'url' | 'date' | 'time' | 'search'>,
        default: 'text'
    },
    disabled: {
        type: Boolean,
        default: false
    },
    required: {
        type: Boolean,
        default: false
    },
    placeholder: {
        type: String,
        default: ''
    },
    maxlength: {
        type: Number as PropType<number | null>,
        default: null
    },
    max: {
        type: [Number, String],
        default: null,
    },
    min: {
        type: [Number, String],
        default: null,
    },
    error: {
        type: String,
        default: ''
    },
    showRequired: {
        type: Boolean,
        default: false
    },
    autocomplete: {
        type: String,
        default: 'on'
    },
    readonly: {
        type: Boolean,
        default: false
    },
    allowPickerTrigger: {
        type: Boolean,
        default: false
    },
    lastTriggerPickerTime: {
        type: Number,
        default: () => Date.now()
    }
})

const emit = defineEmits(['invalid', 'update:model-value', 'focus', 'blur', 'change'])

const slots = useSlots()

const hasIcon = computed<boolean>( () => !!slots.icon)

const input : Ref<HTMLInputElement|null> = ref(null)

const onInvalid = (event) => {
    state.hasError = true
    emit('invalid', event)
}

const onBlur = (event) => emit('blur', event)

const onChange = (event) => emit('change', event)

const onInput = (event) => emit('update:model-value', event.target.value)

const onFocus = (event) => emit('focus', event)

const onClick = () => input.value!.focus()

watch(() => props.lastTriggerPickerTime, (newVal, oldVal) => {
    // Some browsers, ie. FF have trouble with the custom styles on the input
    // This helps ensure that native datepickers, etc. can be triggered
    if (!props.allowPickerTrigger || oldVal >= newVal) {
        return
    }
    input.value.focus()
    if (props.type !== 'date') {
        return
    }

    input.value.showPicker()
})

const state = reactive({
    hasError: false
})

watch(() => props.error, () => {
    state.hasError = props.error !== ''
}, { immediate: true } )
</script>

<style scoped lang="scss">

.zInput {
    position: relative;
    cursor: text;
    display: flex;
    align-items: center;
}

.input-container {
    position: relative;
    flex-grow: 1;
}

.zInput__icon--container {
    flex-grow: 0;
    height: 3.5rem;
    display: flex;
    align-items: center;
    padding: 0 0 0 .85rem;
    box-sizing: border-box;
    border-top-left-radius: .125rem;
    border-bottom-left-radius: .125rem;
}

.zInput__icon--container.focus {
    border-top: .0625rem solid var(--zumba-gray-800);
    border-left: .0625rem solid var(--zumba-gray-800);
    border-bottom: .0625rem solid var(--zumba-gray-800);
}

.zInput__icon--container.error {
    border-top: .0625rem solid var(--zumba-error-red);
    border-left: .0625rem solid var(--zumba-error-red);
    border-bottom: .0625rem solid var(--zumba-error-red);
}

.zInput__input {
    margin-bottom: .25em;
    padding-left: .75rem;
    padding-top: .8rem;
    height: 3.125rem;
    box-shadow: none;
    border-top: .0625rem solid var(--zumba-gray-200);
    border-right: .0625rem solid var(--zumba-gray-200);
    border-bottom: .0625rem solid var(--zumba-gray-200);
    border-left: none;
    width: 100%;
    font-size: .875rem;
    color: var(--zumba-gray-800);
    border-radius: .125rem;
    text-overflow: ellipsis;

    /** START Copied from main-mtv.css */
    line-height: 1em;
    background-color: var(--zumba-white);
    box-sizing: border-box;
    transition: border-color .15s linear,background .15s linear;
    /** END Copied from main-mtv.css */

    &.with-icon + .zInput__label,
    &.with-icon {
        padding-left: 3rem;
    }
}
/** fix safari > ios14 rendering issue */
.is-safari input:is([type="date"],[type="time"]) {
    &::-webkit-date-and-time-value {
        padding-top: .7rem;
        text-align: left;
    }
    @media screen and (min-width: 48rem) {
        padding-top: 1.4rem;
    }
}

.zInput__input::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 50%;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
    z-index: 1;
}

.zInput__input:not(.icon) {
    border-left: .0625rem solid var(--zumba-gray-200);
    border-top-left-radius: .125rem;
    border-bottom-left-radius: .125rem;
}

.zInput__input:disabled {
    background-color: var(--zumba-gray-100);
    border-color: var(--zumba-gray-300);
}

.zInput__input + .zInput__label,
.zInput__input + .icon + .zInput__label {
    position: absolute;
    top: 1rem;
    bottom: 0;
    left: 0;
    width: 100%;
    transition: 0.2s;
    color: var(--zumba-gray-400);
    font-size: .875rem;
    font-weight: normal;
    padding-left: 0.85rem;
    cursor: text;
    box-sizing: border-box;
}

.theme-zumba .zInput__label {
    font-family: var(--zumba-font-secondary);
}

.zInput__input + .icon {
    position: absolute;
    top: 0;
    left: 1rem;
    height: 100%;
    display: flex;
    align-items: center;
}

.zInput__input + .icon + .zInput__label {
    left: 2.25rem;
}

.zInput__input:focus + .zInput__label,
.zInput__input:not(:placeholder-shown) + .zInput__label,
.zInput__input:-webkit-autofill + .zInput__label,
.zInput__input:focus + .icon + .zInput__label,
.zInput__input:not(:placeholder-shown) + .icon + .zInput__label {
    bottom:100%;
    margin-top:-.5rem;
    font-size: .7rem;
    top: .9rem;
}

.zInput__input:focus {
    outline: 0 none;
    box-shadow: none;
    background: var(--zumba-white);
    border-top: .0625rem solid var(--zumba-gray-800);
    border-right: .0625rem solid var(--zumba-gray-800);
    border-bottom: .0625rem solid var(--zumba-gray-800);
}

.zInput__input:not(.icon):focus {
    border-left: .0625rem solid var(--zumba-gray-800);
}

.zInput__error {
    font-family: var(--zumba-font-secondary);
    color: var(--zumba-error-red);
    font-size: .75rem;
    display: block;
}

.zInput__error .zInput__input,
.zInput__input:not(.icon).zInput__error,
.zInput__input:not(.icon).zInput__error:focus {
    border-color: var(--zumba-error-red);
}

.zInput__icon--container {
    position: absolute;
    top: 0;
    height: 3.5rem;
    display: flex;
    align-items: center;
}

.zInput__right-slot {
    position: absolute;
    right: .825rem;
    top: 50%;
    transform: translateY(-50%);
    z-index: 0;
}


/** STRONG */
.theme-strong .zInput__input:focus {
    background: var(--zumba-white);
}

</style>



