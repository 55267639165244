<template>
    <ul
        v-if="props.open"
        class="footer-bar-mobile-nav"
    >
        <li><a :href="props.privacyPolicyLink">{{ props.privacyPolicyText }}</a></li>
        <li><a :href="props.trademarkLink">{{ props.trademarkText }}</a></li>
        <li><a :href="props.termsOfUseLink">{{ props.termsOfUseText }}</a></li>
        <li><a :href="props.careersLink">{{ props.carrersText }}</a></li>
    </ul>
</template>

<script setup lang="ts">
const props = defineProps({
    open: {
        type: Boolean,
        default: false,
        required: true
    },
    privacyPolicyLink: {
        type: String,
        default: '',
        required: true
    },
    privacyPolicyText: {
        type: String,
        default: 'Privacy Policy'
    },
    trademarkText: {
        type: String,
        default: 'Trademark'
    },
    termsOfUseText: {
        type: String,
        default: 'Terms Of Use'
    },
    carrersText: {
        type: String,
        default: 'Careers'
    },
    trademarkLink: {
        type: String,
        default: '/trademark'
    },
    termsOfUseLink: {
        type: String,
        default: '/support/terms-of-use'
    },
    careersLink: {
        type: String,
        default: 'https://careers.zumba.com'
    }
})
</script>

<style scoped>
.footer-bar-mobile-nav {
    position: absolute;
    left: 0;
    width: 100%;
    background-color: #2f3337;
    list-style: none;
    margin: 0;
    padding: 0;
    z-index: 5000;
}

.footer-bar-mobile-nav a {
    text-decoration: none;
    color: #fff
}

.footer-bar-mobile-nav li {
    border-bottom: 1px solid #cacbcf1a;
    padding: 1em 2em 0.8em;

}

@media only screen and (max-width: 30rem) {
    .footer-bar-mobile-nav {
        bottom: 63px;
    }
}

@media only screen and (max-width: 23rem) {
    .footer-bar-mobile-nav {
        bottom: 77px;
    }
}
</style>
