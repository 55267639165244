<template>
    <div class="nav-mobile-profile">
        <span
            class="nav-mobile-profile-name"
            :class="[isZumbaThemed ? ['z-b4', 'f-inter'] : 'z-b3']"
        >
            {{ profileName }}
        </span>
        <div class="nav-mobile-icons">
            <a
                v-if="!isPreMembership"
                :href="statusCardUrl.href"
                v-bind="statusCardUrl.attributes"
                target="_blank"
                class="status-card"
            >
                <MembershipIconSVG
                    class="nav-mobile-icon"
                />
            </a>
            <a
                :href="myAccountUrl.href"
                v-bind="myAccountUrl.attributes"
            >
                <SettingsIconSVG
                    class="nav-mobile-icon"
                />
            </a>
        </div>
    </div>
</template>

<script setup lang="ts">
import { PropType } from "vue";
import MembershipIconSVG from '@icons/navigation/ic_memberships.svg';
import SettingsIconSVG from '@icons/navigation/ic_settings.svg';
import { NavLink } from '@ts/Navigation/types';
import { theme, ThemeType } from "@ts/Util/theme";

defineProps({
    profileName: { type: String, default: ''},
    isPreMembership: { type: Boolean, default: false },
    myAccountUrl: {
        type: Object as PropType<NavLink>,
        required: true
    },
    statusCardUrl: {
        type: Object as PropType<NavLink>,
        required: true
    },
})

const isZumbaThemed = theme.value === ThemeType.Zumba;
</script>

<style lang="scss" scoped>
.nav-mobile-profile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .7rem 1.5rem;
}

.nav-mobile-profile-name {
    color: var(--zumba-white);
}

.nav-mobile-icons {
    display: flex;
    gap: 1rem;
}

.nav-mobile-icon {
    width: 1.5rem;
    height: 1.5rem;
}

.nav-mobile-icon :deep(path) {
    fill: var(--zumba-white);
    width: 1.5rem;
    height: 1.5rem;
}

.theme-zumba {
    .nav-mobile-profile {
        background-color: var(--zumba-gray-800);
        border-bottom: 1px solid var(--zumba-gray-600);
    }
    .nav-mobile-profile-name {
        font-weight: 700;
    }
}

.theme-strong {
    .nav-mobile-profile {
        background-color: var(--strong-primary-gray);
        border-bottom: 1px solid var(--strong-neutral-gray-500);
    }
    .nav-mobile-profile-name {
        font-weight: 400;
    }
}
</style>
