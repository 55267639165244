<template>
    <label
        :class="classes"
        :for="id"
    >
        <input
            :id="id"
            v-model="isChecked"
            :name="name"
            :value="value"
            type="checkbox"
            :disabled="disabled"
            class="input"
            @click="isFocused = false"
            @focus="isFocused = true"
            @blur="isFocused = false"
        >
        <span class="label">
            {{ label }}
        </span>
    </label>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { v4 as uuid } from 'uuid'

export default defineComponent({
    name: 'FormCheckPill',
    props: {
        name: {
            type: String,
            required: true,
        },
        value: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        /**
         * Used by vue to bind the checkbox check state to `v-model` set on the component.
         */
        modelValue: {
            type: Boolean,
            default: false,
        },
        fullWidth: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue'],
    setup(props, context) {
        const id = uuid()

        const isFocused = ref(false)

        const isChecked = computed({
            get(): boolean {
                return props.modelValue
            },
            set(isChecked: boolean): void {
                context.emit('update:modelValue', isChecked);
            }
        })

        const classes = computed(() => {
            return {
                'outer-label': true,
                checked: isChecked.value,
                disabled: props.disabled,
                focused: isFocused.value,
                'full-width': props.fullWidth,
            }
        })

        return {
            id,
            isChecked,
            isFocused,
            classes,
        }
    },
})
</script>

<style scoped>
.outer-label {
    display: inline-flex;
    position: relative;
    background-color: var(--zumba-gray-100);
    padding: .5rem 1rem;
    border-radius: .5rem;
    border: .125rem solid var(--zumba-gray-100);
    cursor: pointer;
}

.full-width {
    display: flex;
    padding: .5rem 0;
    width: 100%;
    justify-content: center;
}

.checked {
    background-color: var(--zumba-gray-800);
    color: var(--zumba-white);
    border-color: var(--zumba-gray-800);
}

@media only screen and (min-width: 48rem) {
    .outer-label.focused {
        outline: 2px solid var(--zumba-plum);
        outline-offset: 2px;
    }
}

.label {
    font-weight: bold;
    font-size: .875rem;
    color: var(--zumba-gray-800);
    background-color: var(--zumba-gray-100);
}

.checked .label {
    color: var(--zumba-gray-100);
    background-color: var(--zumba-gray-800);
}

.disabled {
    cursor: not-allowed;
}

.disabled .label {
    opacity: 0.5;
}

.input {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
}
</style>
