<template>
    <div class="feedback-wrapper">
        <!-- eslint-disable vue/no-v-html -->
        <p
            class="f-inter"
            v-html="t.feedbackHeader"
        />
        <!--eslint-enable-->
        <div>
            <form-check-pill-group
                v-model="state.selectedFeedbackLabels"
                name="selected_feedback_labels[]"
                :class="{ 'centered': true }"
                :items="feedbackLabelsOptions"
                @item-changed="onSelectedFeedbackLabelHandler"
            />
            <div class="separator" />
            <text-area
                v-if="isSomethingElseFeedbackLabelSelected"
                v-model="state.comment"
                name="comment"
                :rows="5"
                :label="t.whatHappened"
                :maxlength="200"
            />
            <div class="separator" />
            <ZButton
                primary
                full-width
                class="tip--custom-tip--button"
                @click="onFeedbackSubmittedHandler"
            >
                {{ t.submitFeedback }}
            </ZButton>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, ComputedRef, PropType, ref, Ref } from 'vue'
import FormCheckPillGroup from '@components/Core/FormCheckPillGroup.vue'
import TextArea from "@components/Core/TextArea.vue"
import ZButton from "@components/Core/ZButton.vue"
import { validateTranslations } from "@ts/Util/i18n";
import { feedbackLabelOption } from "@modules/Rating/RatingHandler.vue";

const localT = {
    feedbackHeader: 'What could they do better?',
    whatHappened: 'Tell us what happened',
    submitFeedback: 'Submit Feedback',
};

const SOMETHING_ELSE_FEEDBACK_LABEL_SLUG = 'something-else'

export type FeedbackState = {
    selectedFeedbackLabels: Record<string, boolean>,
    comment: string,
}

export default defineComponent({
    name: 'Feedback',
    components: {
        FormCheckPillGroup,
        TextArea,
        ZButton
    },
    props: {
        t: {
            type: Object,
            default: () : Object => localT,
            validator: (value : Object) => validateTranslations(value, localT),
        },
        feedbackLabelsOptions: {
            type: Array as PropType<Array<feedbackLabelOption>>,
            required: true,
        },
        comment: {
            type: String,
            default: ''
        }
    },
    emits: [
        'feedback:submitted',
    ],
    setup (props, ctx) {
        const state = reactive({
            selectedFeedbackLabels: {},
            comment: '',
        } as FeedbackState)

        props.feedbackLabelsOptions.forEach((feedbackLabelOption: feedbackLabelOption) => {
            state.selectedFeedbackLabels[feedbackLabelOption.value] =
                Boolean(feedbackLabelOption.hasOwnProperty('checked') && feedbackLabelOption.checked)
        })

        const onFeedbackSubmittedHandler = () => {
            ctx.emit('feedback:submitted', state)
        }

        const onSelectedFeedbackLabelHandler = (feedbackLabel: string, checked: boolean) => {
            state.selectedFeedbackLabels[feedbackLabel] = checked
        }

        const isSomethingElseFeedbackLabelSelected: ComputedRef<boolean> = computed(() => {
            return Object.keys(state.selectedFeedbackLabels).includes(SOMETHING_ELSE_FEEDBACK_LABEL_SLUG)
                && state.selectedFeedbackLabels[SOMETHING_ELSE_FEEDBACK_LABEL_SLUG]

        })
        return {
            state,
            onFeedbackSubmittedHandler,
            onSelectedFeedbackLabelHandler,
            isSomethingElseFeedbackLabelSelected
        }
    },
})
</script>

<style scoped>
.feedback-wrapper {
    width: 100%;
    max-width: 30rem;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.feedback--header {
    text-align: center;
}

.separator {
    margin-bottom: 2rem;
}

.tip--header {
    text-align: center;
}

</style>
