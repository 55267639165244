<template>
    <div class="container f-inter">
        <textarea
            :id="uniqueID"
            :name="name"
            :value="modelValue"
            :class="classes"
            :disabled="disabled"
            :required="required"
            :maxlength="maxlength"
            :placeholder="placeholder || ' '"
            :rows="rows"
            @input="onInput"
            @focus="onFocus"
            @scroll="debounce(onScroll, [$event], 25)"
        />
        <label
            :for="uniqueID"
            :class="labelClasses"
        >{{ label }}</label>
        <div class="footer">
            {{ footer }}
        </div>
        <p
            v-if="Boolean(error)"
            class="error"
        >
            {{ error }}
        </p>
    </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { v4 as uuid } from 'uuid'

const uniqueID = uuid()

/**
 * Note: This component works with `v-model` so you can use `<form-input v-model="inputVar" />` to bind the input
 * value to some local var.
 */
const props = defineProps({
    name: {
        type: String,
        required: true
    },
    label: {
        type: String,
        required: true
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    required: {
        type: Boolean,
        default: false,
    },
    footer: {
        type: String,
        default: '',
    },
    maxlength: {
        type: Number,
        default: null,
    },
    rows: {
        type: Number,
        default: 4,
    },
    /**
     * Used by vue to bind the input value to `v-model` set on the component.
     */
    modelValue: {
        type: String,
        default: ''
    },
    error: {
        type: String,
        default: ''
    },
    placeholder: {
        type: String,
        default: ''
    }
})

const emit = defineEmits(['update:model-value', 'focus'])

const scrolled = ref(false)

const classes = computed(() => {
    return {
        "text-area": true,
        "error": props.error.length > 0
    }
})

const labelClasses = computed(() => {
    return {
        "label": true,
        "scrolled": scrolled.value
    }
})

const debounce = () => {
    const timeout: any = null;
    return function (fnc: CallableFunction, args: Array<any>, delayMs: number) {
        clearTimeout(timeout);
        timeout.value = setTimeout(() => fnc(...args), delayMs || 500);
    }
}

const onInput = (event) => {
    emit('update:model-value', event.target.value)
}

const onFocus = () => {
    emit('focus')
}

const onScroll = (event) => {
    const scrollDist = event.target.scrollTop
    if (scrollDist > 0) {
        scrolled.value = true
        return
    }
    scrolled.value = false
}

</script>

<style scoped>
.container {
    position: relative;
}

.text-area {
    resize: none;
    width: 100%;
    border: .0625rem solid var(--zumba-gray-200);
    border-radius: .125rem;
    padding-left: .75rem;
    padding-top: 1.76rem;
    font-size: .875rem;
    color: var(--zumba-gray-800);
    box-sizing: border-box;
}

.text-area:disabled {
    background-color: var(--zumba-gray-100);
    border-color: var(--zumba-gray-300);
}

.text-area + .label {
    box-sizing: border-box;
    position: absolute;
    top: .5rem;
    left: 0;
    transition: 0.2s;
    width: 100%;
    color: var(--zumba-gray-400);
    font-size: .875rem;
    font-weight: normal;
    text-align: left;
    padding-left: .85rem;
    background-color: unset;
    pointer-events: none;
    text-wrap: auto;
}

.text-area:not(:placeholder-shown) + .label,
.text-area:focus + .label {
    font-size: .7rem;
}

.text-area + .label.scrolled {
    display: none;
}

.text-area:focus {
    outline: 0 none;
    box-shadow: none;
    background: none;
    border-color: var(--zumba-gray-800);
}

.text-area.error {
    border: .0625rem solid var(--zumba-error-red)
}

.error {
    margin: .25rem 0 .5rem .25rem;
    color: var(--zumba-error-red);
    font-family: var(--zumba-font-secondary);
    font-size: .75rem;
    text-align: left;
}

.footer {
    font-size: .75rem;
    color: var(--zumba-gray-500);
    text-align: right;
    margin-top: .375rem;
}
</style>
