<template>
    <div
        :class="[
            'options',
            classes,
            {'centered':centered},
        ]"
    >
        <div
            v-for="(item, index) in items"
            :key="index"
            class="option"
        >
            <FormCheckPill
                v-model="checked[item.value]"
                :name="item.name || name"
                :label="item.label"
                :value="item.value"
                :disabled="item.disabled || disabled"
                :full-width="fillSpace"
                @change="onLastChanged"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import FormCheckPill from '@components/Core/FormCheckPill.vue';

export default defineComponent({
    name: 'FormCheckPillGroup',
    components: { FormCheckPill },
    props: {
        items: {
            type: Array,
            default: () => []
        },
        /**
         * Used by vue to bind the checkbox check state to `v-model` set on the component.
         */
        modelValue: {
            type: Object,
            default: () => {
                return {};
            }
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
            default: 'selected[]'
        },
        fillSpace: {
            type: Boolean,
            default: false
        },
        centered: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue', 'item-changed'],
    computed: {
        classes(): Object {
            return {
                fill: this.fillSpace
            }
        },
        checked: {
            get(): Object {
                return this.modelValue
            },
            set(checked: Object): void {
                this.$emit('update:modelValue', checked);
            }
        }
    },
    methods: {
        onLastChanged(e) {
            const lastVal = e.target.value
            const isChecked = e.target.checked
            this.$emit('item-changed', lastVal, isChecked)
        }
    }
})
</script>

<style scoped>
.options {
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
}

.centered {
    justify-content: center;
}

.fill {
    flex-wrap: nowrap;
}

.fill .option {
    flex-grow: 1;
    flex-basis: 100%;
    margin: 0 .125rem;
}
</style>
