<template>
    <ul
        class="nav-group"
    >
        <li
            class="nav-group-title z-b3"
            :class="{ 'expanded': isExpanded,
                      'is-top-level': props.isTopLevel,
                      'active': isActive
            }"
            @click="toggleExpand"
        >
            <slot name="title">
                {{ props.title }}
            </slot>
        </li>
        <transition name="expand">
            <ul
                v-if="isExpanded"
                class="nav-group-items"
            >
                <slot />
            </ul>
        </transition>
    </ul>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { ref, withDefaults, watchEffect } from 'vue';

const props = withDefaults(defineProps<{
    id?: string,
    title?: string,
    active?: boolean,
    expanded?: boolean,
    indepententActiveState?: boolean, //the active state is handled independently from the expanded state
    isTopLevel?: boolean,
}>(), {
    id: '',
    title: '',
    active: false,
    expanded: false,
    indepententActiveState: false,
    isTopLevel: false,
});

const emit = defineEmits(['toggle-expand']);

const isExpanded = ref(props.expanded);
const isActive = ref(!props.indepententActiveState ? props.expanded : props.active);

onMounted(() => {
    isExpanded.value = props.expanded;
    isActive.value = props.active;

    if (!props.indepententActiveState) {
        isActive.value = isExpanded.value;
    }
});

watchEffect(() => {
    isActive.value = props.active;

    if (!props.indepententActiveState) {
        isActive.value = isExpanded.value;
    }
});

const toggleExpand = () => {
    isExpanded.value = !isExpanded.value;
    if (!props.indepententActiveState) {
        isActive.value = isExpanded.value;
    }

    emit('toggle-expand', { id: props.id, expanded: isExpanded.value});
};
</script>
<style lang="scss" scoped>
.nav-group {
    position: relative;

    .nav-group-title {
        background-color: var(--zumba-gray-600);
        border-bottom: 1px solid var(--zumba-gray-600);
        font-family: var(--zumba-font-secondary);
        padding: 1rem 1.5rem;
        color: var(--zumba-white);
        cursor: pointer;

        &::after {
            content: '';
            border-style: solid;
            border-width: 2px 2px 0 0;
            border-color: rgba(255, 255, 255, 1.0);
            display: inline-block;
            right: 1.5rem;
            position: absolute;
            top: 1.25em;
            transform: rotate(135deg);
            transform-origin: center;
            vertical-align: top;
            height: .625rem;
            width: .625rem;
            transition: transform .3s cubic-bezier(0.075, 0.82, 0.165, 1);
        }

        &.is-top-level {
            background-color: var(--zumba-gray-800);
        }

        &.expanded {
            font-weight: 700;

            &::after {
                transform: rotate(-45deg);
                top: 1.45rem;
            }
        }

        &.active {
            background-color: var(--zumba-plum);
        }
    }

    .nav-group-items {
        overflow: hidden;
    }

    .expand-enter-active, .expand-leave-active {
        transition: height .3s ease;
        interpolate-size: allow-keywords;
    }
    .expand-enter, .expand-leave-to {
        height: 0;
    }
    .expand-enter-to, .expand-leave {
        height: auto;
    }
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.theme-strong {
    .nav-group {
        .nav-group-title {
            background-color: var(--strong-neutral-gray-500);
            border-bottom: 1px solid var(--zumba-gray-600);
            font-family: var(--strong-font-default);

            &.is-top-level {
                background-color: var(--strong-primary-gray);
            }

            &.active {
                background-color: var( --strong-secondary-dark-coral);
            }
        }
    }
}
</style>