<template>
    <FooterBarMobileNav
        :open="state.accordionLinksOppened"
        :privacy-policy-link="props.privacyPolicyLink"
        :privacy-policy-text="props.t.privacyPolicy"
        :trademark-link="props.trademarkLink"
        :trademark-text="props.t.trademark"
        :terms-of-use-link="props.termsOfUseLink"
        :terms-of-use-text="props.t.termsOfUse"
        :careers-link="props.careersLink"
        :carrers-text="props.t.careers"
    />
    <section class="z-footer-bar">
        <ZumbitoSVG />
        <div>
            <ul class="z-footer-bar__links">
                <li>
                    <a :href="props.privacyPolicyLink">
                        {{ props.t.privacyPolicy }}
                    </a>
                </li>
                <li>
                    <a :href="props.trademarkLink">
                        {{ props.t.trademark }}
                    </a>
                </li>
                <li>
                    <a :href="props.termsOfUseLink">
                        {{ props.t.termsOfUse }}
                    </a>
                </li>
                <li>
                    <a
                        :href="props.careersLink"
                        rel="noopener"
                        target="_blank"
                    >
                        {{ props.t.careers }}
                    </a>
                </li>
                <li>
                    <a
                        @click.prevent="handleLangIconClick"
                    >
                        <picture>
                            <source
                                :srcset="('data:image/webp;base64,'+props.countryWebpBase64)"
                                type="image/webp"
                            >
                            <img
                                class="flag-only"
                                :src="'/img/blt/icons/country-flags/'+props.countryCode+'.png'"
                                :alt="props.countryCode.toUpperCase()"
                            >
                        </picture>
                    </a>
                </li>
            </ul>
            <div class="z-footer-bar__copyright">
                <p>© Copyright {{ dynamicYear }} Zumba Fitness, LLC.</p>
                <a
                    v-if="!hideMobileMenu"
                    @click.prevent="handleArrowIconClick"
                >
                    <ArrowSVG
                        :class="{
                            'z-footer-bar__copyright__greater-sign': !state.accordionLinksOppened,
                            'z-footer-bar__copyright__greater-sign--oppened': state.accordionLinksOppened
                        }"
                    />
                </a>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import ZumbitoSVG from '@icons/footer/zumbito.svg'
import ArrowSVG from '@icons/arrow.svg'
import FooterBarMobileNav from '@components/Footer/FooterBarMobileNav.vue';

interface ZFooterBarProps {
    t: {
        privacyPolicy?: string,
        trademark?: string,
        termsOfUse?: string,
        careers?: string
    },
    localeSelectionRedirectLink: string,
    countryCode: string,
    countryWebpBase64: string,
    privacyPolicyLink?: string,
    termsOfUseLink?: string,
    careersLink?: string,
    trademarkLink?: string,
    hideMobileMenu?: boolean
}

const props = withDefaults(defineProps<ZFooterBarProps>(), {
    t: () => ({
        privacyPolicy: 'Privacy Policy',
        trademark: 'Trademark',
        termsOfUse: 'Terms Of Use',
        careers: 'Careers'
    }),
    localeSelectionRedirectLink: '/en-US/localization?redirect=%2Fen-US',
    countryCode: 'us',
    countryWebpBase64: 'UklGRrYAAABXRUJQVlA4IKoAAABwAgCdASoQAAsAAgA0JbACdAb4DkqQhc3CBqfAAP63YIz8qTX+DsIAtspzkjbllBn5vr/E6RyzU74/u/Y71fLaA53FTsFv5Bw2D5Mdnx/8ycV0vn/ioRPkJF+ECZCziHyA/U7lqEfrn52fSXllRV+fjEtDtDqx2FNMkDYnZj1L5lAsf/jqpvcs+WATlstUL++7uxX9MoMU+4UdUfn165i/8KR3BGde/xIAAA==',
    privacyPolicyLink: '/support/policy/privacy',
    termsOfUseLink: '/support/terms-of-use',
    careersLink: 'https://careers.zumba.com',
    trademarkLink: '/trademark',
    hideMobileMenu: false
})

const state = reactive({accordionLinksOppened: false})
const mobileWidthBreakpoint = 480
const dynamicYear = new Date().getFullYear();

const handleLangIconClick = (): void => {
    if(document.body.offsetWidth > mobileWidthBreakpoint) {
        window.location.href = '/localization?redirect='+ props.localeSelectionRedirectLink
        return
    }
}

const handleArrowIconClick = (): void => {
    document.body.offsetWidth <= mobileWidthBreakpoint && (
        state.accordionLinksOppened = !state.accordionLinksOppened
    )
}
</script>

<style scoped>
/* Common */
* {
    --text-size: 0.875rem;
}

.z-footer-bar__links--mobile {
    display: none;
}

.z-footer-bar {
    display: flex;
    position: relative;
    align-items: center;
    justify-items: center;
    background-color: #2f3337;
    padding: 1em 1.75em;
}

.z-footer-bar * {
    font-family: var(--zumba-font-default);
}

.z-footer-bar > div {
    display: inherit;
    justify-content: space-between;
    flex-grow: 1;
}

.z-footer-bar__links {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0 0 0 1em;
    gap: 1em;
}

.z-footer-bar__links > li {
    font-size: var(--text-size);
}

.z-footer-bar__links > li > a {
    color: var(--zumba-white);
    text-decoration: none;
    font-weight: 400;
}

.z-footer-bar__links > li > a:hover {
    color: #1D1D24;
}

.z-footer-bar__copyright {
    display: flex;
    align-items: center;
    margin-left: auto;
    color: var(--zumba-white);
    font-size: var(--text-size);
    gap: 1em;
}

.z-footer-bar__copyright > p {
    margin: 0;
    font-size: inherit;
    color: var(--zumba-white);
}

.z-footer-bar__copyright > a {
    display: flex;
    align-items: center;
    gap: 0.5em;
    cursor: pointer;
}

.z-footer-bar__copyright__greater-sign,
.z-footer-bar__copyright__greater-sign--oppened {
    height: 1em;
    transition: transform .3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.z-footer-bar__copyright__greater-sign :deep(path),
.z-footer-bar__copyright__greater-sign--oppened :deep(path) {
    fill: var(--zumba-white);
}
.z-footer-bar__copyright > a {
    display: none;
}

.flag-only {
    margin-bottom: 2px;
}

@media only screen and (max-width: 60rem) {
    .z-footer-bar > div {
        flex-flow: column;
        align-items: flex-end;
        gap: .3em;
    }
}

@media only screen and (max-width: 30rem) {
    .z-footer-bar__copyright > a {
        display: block;
    }

    .z-footer-bar__links {
        display: none;
    }

    .z-footer-bar__copyright__greater-sign, .z-footer-bar__copyright__greater-sign--oppened {
        transform: rotate(-90deg);
    }

    .z-footer-bar__copyright__greater-sign--oppened {
        transform: rotate(90deg);
    }
}

@media only screen and (max-width: 23rem) {
    .z-footer-bar__copyright > p {
        width: 10em;
        text-align: end;
    }
}

/* Strong */
.theme-strong .z-footer-bar * {
    font-family: var(--zumba-font-default);
}
</style>
